<template src="./FormsCharts.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import {Chart as ChartJS,Title,Tooltip,Legend,ArcElement,LineElement,PointElement,CategoryScale,LinearScale} from 'chart.js';
  import { Doughnut, Line } from 'vue-chartjs';
  ChartJS.register(CategoryScale, LinearScale, ArcElement,LineElement,PointElement, Title, Tooltip, Legend)
  export default{
    name: 'FormsCharts',
    components: {
      Doughnut,
      Line
    },
    mounted(){
      this.landing = this.$route.params.referencia;
      if(!this.landing){
        this.$router.push({ name: 'managerlanding' });
      }
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      landing: null,
      isloading: true,
      datactx: {
        labels: [],
        datasets: [{
          label: "Donaciones",
          borderColor: "rgb(75, 192, 192)",
          data: [],
          fill: !1,
          tension: .1
        }]
      },
      optionsctx: {
        responsive: !0,
        maintainAspectRatio: !1,
        plugins: {
          title: {
            display: !0,
            text: "Recaudo por mes"
          }
        }
      },
      datactx2: {
        labels: [],
        datasets: [{
          backgroundColor: ["#005588", "#f85898", "#fe9705", "#327d32", "#a436e9", "#27b692", "#2e4182", "#c2236f", "#fe2276", "#86da2d"],
          data: []
        }]
      },
      optionsctx2: {
        responsive: !0,
        maintainAspectRatio: !1,
        plugins: {
          title: {
            display: !0,
            text: "Recaudo por Target"
          }
        }
      }
    }),
    methods: {

      async load(){
        try {
          await axios.get(this.setting.params.api + '/admin/landing/form/load/'+this.landing,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let result = res.data;
            this.datactx2.labels = result.formularios.map((e => e.objetivo));
            this.datactx2.datasets[0].data = result.formularios.map((e => e.recaudo));
          });
          await axios.get(this.setting.params.api + '/admin/donaciones/getmonth/'+this.landing,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let result = res.data;
            this.datactx.labels = result.recaudo.map((e => e.label));
            this.datactx.datasets[0].data = result.recaudo.map((e => e.valor))
          });
          this.isloading = false;
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      }

    }
  }
</script>