<div class="my-5">
  <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" background-color="#eeeeee" :opacity="0.9">
    <div class="text-center">
      <div>
        <v-progress-circular color="primary" :size="75" indeterminate />
      </div>
      <div>
        {{ output.message }}
      </div>
    </div>
  </loading>
  <div class="d-flex flex-column justify-center">
    <div class="text-center mb-3">
      <div class="transaction-text-primary">¡Sé parte del cambio!</div>
      <div class="transaction-text-secondary">Por favor completa los datos, abrirá la plataforma de PSE en esta misma ventana para que puedas finalizar tu donación.</div>
    </div>
    <div class="d-flex justify-center my-3">
      <div class="mr-3">
        <v-img src="@/assets/images/logo-pse.png" width="50"/>
      </div>
      <v-divider vertical />
      <div class="ml-3">
        <v-img src="@/assets/images/wompi_logo.png" width="50" />
      </div>      
    </div>
    <div>
      <v-select v-model="transaction.pse_user_type" label="Tipo de Persona" 
      :items="[{ title: 'Natural', value: 0 }, { title: 'Jurídica', value: 1 }]" 
      item-title="title" 
      item-value="value"
      :error-messages="v$.transaction.pse_user_type.$errors.map(e => e.$message)"/>
      <v-select v-model="transaction.pse_institution_code" label="Entidad Financiera" 
      :items="banklist" 
      item-title="financial_institution_name" 
      item-value="financial_institution_code"
      :error-messages="v$.transaction.pse_institution_code.$errors.map(e => e.$message)"/>
      <v-checkbox v-model="acceptance">
        <template v-slot:label>
          <div class="text-caption">
            Acepto haber leído los téminos, condiciones y la política de privacidad para hacer esta transacción.
          </div>
        </template>
      </v-checkbox>
    </div>
    <div>
      <!-- notificaciones -->
      <v-alert closable title="Error" :text="output.error" color="red-darken-2" v-if="output.error"></v-alert>
    </div>
    <div class="mb-auto"></div>
    <div class="mb-3">
      <v-btn color="warning" block rounded="lg" variant="flat" size="large" text="Finalizar" 
      style="color: white !important;bottom: 5px;" :disabled="!acceptance" @click="begintransaction()"/>
      <div class="d-flex align-center">
        <div class="pa-3">
          <v-icon>mdi-security</v-icon>
        </div>
        <div class="transaction-text-legal">
          Esta transacción se realizará con Wompi y cuenta con una certificación PCI DSS para al manejo, transmisión y procesamiento seguro de datos de tarjeta y una encriptación SSL para garantizar la protección de estos.
        </div>
      </div>
    </div>
    
  </div>
</div>