<template>
  <v-app-bar color="primary" :elevation="2">
    <template v-slot:prepend>
      <v-img class="ml-2" src="@/assets/images/logo.png" width="60" />
    </template>
    <v-app-bar-title>PLATAFORMA DONANTES</v-app-bar-title>
    <v-spacer></v-spacer>
    <template v-slot:append>
      <v-btn class="text-white" size="large" icon @click="signout">
        <v-tooltip text="salir"  activator="parent" location="bottom"></v-tooltip>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </template>
    <v-dialog v-model="process" max-width="320" persistent>
      <v-list class="py-2" color="primary" elevation="12" rounded="lg">
        <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
          <template v-slot:prepend>
            <div class="pe-4">
              <v-icon color="primary" size="x-large"></v-icon>
            </div>
          </template>
          <template v-slot:append>
            <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
          </template>
        </v-list-item>
      </v-list>    
    </v-dialog>
  </v-app-bar>
</template>
<script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();
  const process = ref(false);


  function signout(){
    process.value = true;
    localStorage.removeItem('status');
    localStorage.removeItem('type');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('donantstate');
    localStorage.removeItem('donantauthorized');
    router.push({ name: 'home' });
  }

  onMounted(()=>{
    !localStorage.donantauthorized ? (router.push({name: 'home'})) : localStorage.type = 'DON';
  });

  
</script>