<template src="./views/TransactionConfirm.html"></template>
<script>
  import Setting from '@/models/setting';
  import Transaction from '@/models/transaction';
  import axios from 'axios';
  import Loading from 'vue-loading-overlay';

  export default{
    name: 'TransactionConfirm',
    components: { Loading },
    data: () => ({
      setting: new Setting,
      overlay: true,
      transaction: new Transaction,
      status: null,
      form: {
        id: null,
        code: null
      }
    }),
    mounted(){
      this.validate();
    },
    methods: {

      async validate(){
        this.form.id = localStorage.formid;
        this.form.code = localStorage.formcode;
        if(!this.form.id || !this.form.code){
          this.status = 'FAILED';
          return;
        }
        if(this.$route.params.id){
          this.transaction.id = this.$route.params.id;
        }else if(this.$route.query.id){
          this.transaction.id = this.$route.query.id;
        }else{
          this.status = 'FAILED';
          return;
        }

        this.delay(3000);

        await axios.get(this.setting.params.wompi + '/transactions/' + this.transaction.id.toString())
        .then(r => {
          this.status = r.data.data.status;
          this.transaction.setcardresult(r.data.data);
        })
        .catch(e => {
          this.status = 'FAILED';
          console.log(e.response.data.error.reason);
        });
        this.overlay = false;
      },

      delay(timeout){
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, timeout);
        });
      },

      openlink(link){
        window.location.assign(link);
      },

      print(){
        window.print();
      }

    }
  }
</script>