<template src="./views/DonantProfile.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import Donation from '@/models/donation.js';
  import swal from 'sweetalert';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric, email } from '@vuelidate/validators';

  export default{
    name: 'DonantProfile',
    setup () {
      return { v$: useVuelidate() }
    },
    props: ['donante'],
    mounted(){
      this.user.status = localStorage.status;
      this.user.type = localStorage.type;
      this.user.name = localStorage.name;
      this.user.token = localStorage.token;
    },
    data: () => ({
      setting: new Setting(),
      donation: new Donation(),
      user: {
        status: null,
        type: null,
        name: null,
        token: null
      },
      isedit: false,
      isloading: false,
      isprocess: false,
      dialogdonation: false,
      dialogauthorization: false,
      dialogcertificate: false,
      fileavn: [],
      itemperiods: [],
      period: null
    }),
    validations () {
      return {
        donante:{ 
          nombres: { required },
          apellidos: { required },
          correo_personal: { required, email },
          correo_laboral: { required, email },
          telefono: { required, numeric },
        }
      }
    },
    methods: {

      async update(){
        if(!await this.v$.$validate()){
          return
        }
        try {
          this.isprocess = true;
          this.isloading = true;
          await axios.put(this.setting.params.api + '/admin/donantes/update',this.donante,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            let data = res.data;
            this.isprocess = false;
            this.isloading = false;
            if(data.status){
              swal({
                title: "success",
                text: data.message, 
                icon: "success",
                buttons: {
                  text: "ACEPTAR",
                }
              });
            }else{
              swal({
                title: "warning",
                text: data.message, 
                icon: "warning",
                buttons: {
                  text: "ACEPTAR",
                }
              });
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async adddonation(){
        try {
          this.isloading = true;
          this.donation.documento = this.donante.documento;
          await axios.post(this.setting.params.api + '/admin/donaciones/add',this.donation,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            let data = res.data;
            this.isprocess = false;
            this.isloading = false;
            this.dialogdonation = false;
            if(data.status){
              swal({title: "success",text: data.message, icon: "success",
                buttons: {
                  text: "ACEPTAR",
                }
              });
            }else{
              swal({title: "warning",text: data.message,icon: "warning",
                buttons: {
                  text: "ACEPTAR",
                }
              });
            }
          })
          .catch(error => {
            this.dialogdonation = false;
            this.showerror(error);
          });
        } catch (error) {
          this.dialogdonation = false;
          this.showerror(error);
        }
      },

      async uploadfileavn(){
        this.dialogauthorization = false;
        try {
          this.isprocess = true;
          let frm = new FormData;
          frm.append('documento',this.donante.documento);
          frm.append('file',this.fileavn[0]);
          await axios.post(this.setting.params.api + '/admin/donantes/file', frm, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              this.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async downloadfileavn(){
        try {
          this.isprocess = true;
          await fetch(this.setting.params.api + '/admin/donantes/get/fileavn/'+this.donante.documento,{
          headers:{ Authorization: localStorage.getItem('token') }
          }).then(async t => {
            this.isprocess = false;
            return t.blob().then((b)=>{
              var a = document.createElement("a");
              a.href = URL.createObjectURL(b);
              a.setAttribute("download", 'acuerdo_'+this.donante.documento+'.pdf');
              a.click();
            })
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async remove(){
        try {
          let confirm = null;
          await swal({title: "¿eliminar registro?",text: '¿Seguro quiere eliminar este registro?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }
          this.isprocess = true;
          await axios.delete(this.setting.params.api + '/admin/donantes/delete/' + this.donante.documento, {
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then( res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              this.showerror({message: data.message});
            }
          });
        }catch(error){
          this.showerror(error);
        }
      },

      async setperiods(){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api + '/admin/donaciones/periodos/'+this.donante.documento, {
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              this.itemperiods = data.periodos;
              this.dialogcertificate = true;
            }else{
              this.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      generatecertificate(){
        this.dialogcertificate = false
        if(this.period){
          this.$router.push({name: 'managercertificate', params: { year: this.period, document: this.donante.documento }});
        }else{
          swal({ title: "warning",
            text: 'Parece que no tienes donaciones registradas, puedes hacer tu primera donación a través de nuestra página unitedwaycolombia.org', 
            icon: "warning",
            buttons: {
              text: "ACEPTAR",
            }
          });
        }        
      },

      generateauthorization(){
        localStorage.setItem('authorization',this.donante.documento);
        this.$router.push({name:'managerauthorization'})
      },

      async refreshtoken(){
        try {
          await axios.put(this.setting.params.api + '/admin/donantes/refreshtoken',this.donante,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            let data = res.data;
            this.isprocess = false;
            this.isloading = false;
            if(data.status){
              swal({title: "success",text: 'Token reestablecido correctamente!', icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              swal({title: "warning",text: data.message, icon: "warning",
                buttons: {text: "ACEPTAR"}
              });
            }
          })
        } catch (error) {
          this.showerror(error);
        }
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({
          title: "ERROR",
          text: error.message, 
          icon: "error",
          buttons: {
            text: "ACEPTAR",
          }
        });
      }

    }
  }
</script>