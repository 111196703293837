<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="pa-5">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-view-dashboard-outline</v-icon>
              <div class="ml-3 text-h4">
                Mi Tablero
              </div>
            </div>
            <div>
              Gestiona tus datos y donaciones.
            </div>
          </div>
        </div>
        <div class="mt-5">
          <v-tabs v-model="tab" align-tabs="center" color="primary">
            <v-tab :value="1">Perfil</v-tab>
            <v-tab :value="2">Donaciones</v-tab>
          </v-tabs>
          <v-tabs-window v-model="tab">
            <v-tabs-window-item :key="1" :value="1">
              <DonantProfile :donante="donant" />
            </v-tabs-window-item>
            <v-tabs-window-item :key="2" :value="2">
              <DonantDonation :documento="donant.documento" />
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </v-card>

      <!-- #progress -->
      <v-dialog v-model="isprocess" max-width="320" persistent>
        <v-list class="py-2" color="primary" elevation="12" rounded="lg">
          <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
            <template v-slot:prepend>
              <div class="pe-4">
                <v-icon color="primary" size="x-large"></v-icon>
              </div>
            </template>
            <template v-slot:append>
              <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
            </template>
          </v-list-item>
        </v-list>    
      </v-dialog>
      <!-- #endprogress -->
    </v-container>
  </v-main>
</div>