<template>
  <div class="w-100 h-100">
    <div class="d-flex flex-column justify-center align-center h-100">
      <div class="text-h6 mb-5">PÁGINA NO ENCONTRADA</div>
      <div>
        <v-img src="@/assets/images/not-found-2.png" width="300" />
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
