<template src="./views/ZoneNequi.html"></template>
<script>
  import Donant from '@/models/donant';
  import Donation from '@/models/donation';
  import Setting from '@/models/setting';
  import axios from 'axios';
  import Loading from 'vue-loading-overlay';
  import Transaction from '@/models/transaction';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric, minLength, maxLength, helpers } from '@vuelidate/validators';

  export default{
    name: 'ZoneNequi',
    setup () {
      return { v$: useVuelidate() }
    },
    components: { Loading },
    props: { donant: Donant, transaction: Transaction },
    data: () => ({
      setting: new Setting,
      donation: new Donation,
      acceptance: false,
      state: true,
      overlay: false,
      overlay_msg: 'Por favor espera...',
      output: {
        error: null
      }
    }),
    validations() {
      return{
        transaction: {
          nequi_number: { 
            required: helpers.withMessage('Requerido.', required),
            numeric:  helpers.withMessage('Solo números.', numeric),
            minLength: helpers.withMessage(() => 'Mínimo 10 caracteres.',  minLength(10)), 
            maxLength: helpers.withMessage(() => 'Máximo 10 caracteres.', maxLength(10))
          }
        }
      }
    },
    methods: {

      async begintransaction(){
        if(!await this.v$.$validate()){
          return;
        }
        this.overlay_msg = 'Iniciando transacción...';
        this.overlay = true;

        //Donante
        try {
          this.overlay_msg = 'Registrando donante...';
          await axios.post(this.setting.params.api + '/donacion/transacciones/add/donate', this.donant)
          .then(r => {
            if(r.data.status){
              this.donation.donante = r.data.data;
              this.donation.formulario = this.transaction.form;
              this.donation.valor = this.transaction.amount;
              this.donation.centavos = this.transaction.amount_in_cents;
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 501.";
              this.state = false;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }

        if(!this.state){ return; }

        //Donación
        try {
          this.overlay_msg = 'Creando donación...';
          await axios.post(this.setting.params.api + '/donacion/transacciones', this.donation)
          .then(r => {
            if(r.data.status){
              this.donation.reference = r.data.reference;
              this.donation.integrity = r.data.integrity;
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 502.";
              this.state = false;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }

        if(!this.state){ return; }

        this.overlay_msg = 'Procesando transacción...';
        let it = null; //idtransaction

        //Validar tipo transaccion
        if(this.transaction.recurrent){
          //Recurrente
          this.overlay_msg = 'Tokenizando método de pago...';
          const nequi_tokenized = { phone_number: this.transaction.nequi_number };
          await axios.post(this.wompi + "tokens/nequi", nequi_tokenized, {
            headers: { accept: "application/json", Authorization: "Bearer " + this.setting.params.key }
          }).then((r => {
            this.transaction.setnequi(r.data.data);
          }));
          //Consultar estado de tokenización
          this.overlay_msg = 'Obteniendo estado de tokenización...';
          let time = 0;
          let token_state = null;
          while (time < 5) {
            time++;
            await this.delay(5000);
            await axios.get(this.wompi + "tokens/nequi/" + this.transaction.token_card)
            .then((r => {
              if(r.data.data.status === "APPROVED"){
                token_state = true;
                time = 5;
              }
            }))
          }

          if(token_state){
            const frm = new FormData;
            frm.append("donants", JSON.stringify(this.donant));
            frm.append("donation", JSON.stringify(this.donation));
            frm.append("transaction", JSON.stringify(this.transaction));
            await axios.post(this.setting.params.api + "/donacion/transacciones/recurrente", frm)
            .then(r => {
              if(r.data){
                it = r.data.data.id;
                this.overlay_msg = 'Por favor revisa tu App Nequi y toca aceptar... por favor no cierres ni recargues esta página';
              }else{
                this.state = false;
              }
            });
          }

        }else{
          //Normal
          const datatransaction = {
            "acceptance_token": this.transaction.acceptance_token,
            "signature": this.donation.integrity,
            "amount_in_cents": this.transaction.amount_in_cents,
            "reference": this.donation.reference,
            "currency": "COP",
            "customer_email": this.donant.correo_personal,
            "payment_method": {
              "type": this.transaction.payment_method_type,
              "phone_number": this.transaction.nequi_number
            },
            "redirect_url": this.setting.params.redir,
            "customer_data": {
              "legal_id": this.donant.documento,
              "legal_id_type": this.donant.tipo_documento,
              "phone_number": this.donant.telefono,
              "full_name": this.donant.nombres + " " + this.transaction.apellidos
            }
          };

          //procesar transacción con wompi
          try {
            await axios.post(this.setting.params.wompi + '/transactions', datatransaction,{
              headers: { accept: "application/json", Authorization: "Bearer " + this.setting.params.key }
            })
            .then(r => {
              if(r.data){
                it = r.data.data.id;
                this.overlay_msg = 'Por favor revisa tu App Nequi y toca aceptar... por favor no cierres ni recargues esta página';
              }else{
                this.state = false;
              }
            });
          } catch (error) {
            this.overlay = false;
            this.error = error.message;
            console.log(error);
            return;
          }
                   
        }

        if(!this.state){
          this.overlay = false;
          this.error = 'Error al procesar la transacción, no se ha aplicado ningún cobro al medio de pago. Código 504.';
          return;
        }

        if(it){
          this.confirm(it);
        }else{
          this.overlay = false;
          this.error = 'Error al obtener respuesta de transacción. Código 509.';
        } 
      },

      async confirm(t){
        let intentos = 5;
        while (intentos > 0) {
          await this.delay(10000);
          await axios.get(this.setting.params.wompi + '/transactions/' + t.toString())
          .then(r => {
            if(r.data.data.status === 'APPROVED'){
              intentos = 0;
            }
          });
          intentos--;
        }
        this.overlay = false;
        this.$router.push({ name: 'landingtransaction', params: { id: t } });
      },

      delay(timeout){
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, timeout);
        });
      }

    }
  }
</script>