<template src="./ManagerDonant.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import Donant from '@/models/donant';
  import swal from 'sweetalert';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric } from '@vuelidate/validators';
  import NavBar from '../tools/NavBar.vue';
  import DonantProfile from '@/components/commons/DonantProfile.vue';
  import DonantDonation from '@/components/commons/DonantDonation.vue';
  export default{
    name: 'ContentDonant',
    setup () {
      return { v$: useVuelidate() }
    },
    components: {
      NavBar,
      DonantProfile,
      DonantDonation,
    },
    mounted(){
      if(!localStorage.getItem('token')){
        this.$router.push({ name: 'home' });
      }
    },
    data: () => ({
      setting : new Setting(),
      donant : new Donant(),
      dialogadd: false,
      isloading: false,
      isprocess: false,
      isdonant: false,
      searchdocument: null,
      tab: 1
    }),
    validations () {
      return {
        searchdocument:{ required, numeric }
      }
    },
    methods: {

      async search(){
        let validate = await this.v$.searchdocument.$validate();
        if(!validate){
          return;
        }

        try {
          this.isprocess = true;
          this.isloading = true;
          await axios.post(this.setting.params.api + '/admin/donantes/view',{documento: this.searchdocument}, {
            headers:{ Authorization: localStorage.token }
          })
          .then(res => {
            this.isprocess = false;
            this.isloading = false;
            let data = res.data;
            if(data.donante.length){
              this.isdonant = true;
              this.donant.setpersonaldata(data.donante[0]);
            }else{
              swal({
                title: "Not found",
                text: "El documento no se encuentra registrado en el sistema.", 
                icon: "warning",
                buttons: {
                  text: "ACEPTAR",
                }
              });
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async add(){
        this.isloading = true;
        try {
          await axios.post(this.setting.params.api + '/admin/donantes/add', this.donant,{
            headers:{ Authorization: localStorage.token }
          })
          .then(res => {
            this.isloading = false;
            this.dialogadd = false;
            let data = res.data;
            if(data.status){
              swal({
                title: "success",
                text: data.message, 
                icon: "success",
                buttons: {
                  text: "ACEPTAR",
                }
              });
            }else{
              this.showerror({message: data.message});
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({
          title: "ERROR",
          text: error.message, 
          icon: "error",
          buttons: {
            text: "ACEPTAR",
          }
        });
      }

    }
  }
</script>
<style>
  @import '@/assets/css/manager.css';
</style>