<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="pa-5">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-account-group</v-icon>
              <div class="ml-3 text-h4">
                Módulo Donantes
              </div>
            </div>
            <div>
              Gestiones de donantes y donaciones.
            </div>
          </div>
          <div class="w-25">
            <div class="d-flex">
              <v-text-field v-model="searchdocument" label="Número de documento" append-inner-icon="mdi-magnify" variant="outlined"
              :error-messages="v$.searchdocument.$errors.map(e => e.$message)" @click:append-inner="search()"
              onkeypress="return event.charCode>=48 && event.charCode<=57"></v-text-field>
              <v-btn class="mx-3" icon :elevation="0" @click="dialogadd = true">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <div v-if="!isdonant" class="text-center">
            <v-img class="ma-auto" src="@/assets/images/not-found.png" width="500" />
          </div>
          <div v-if="isdonant">
            <div class="text-center text-h6">Resultado de la búsqueda donante {{ donant.documento }}</div>
            <div class="mt-5">
              <v-tabs v-model="tab" align-tabs="center" color="primary">
                <v-tab :value="1">Perfil</v-tab>
                <v-tab :value="2">Donaciones</v-tab>
              </v-tabs>
              <v-tabs-window v-model="tab">
                <v-tabs-window-item :key="1" :value="1">
                  <DonantProfile :donante="donant" />
                </v-tabs-window-item>
                <v-tabs-window-item :key="2" :value="2">
                  <DonantDonation :documento="donant.documento" />
                </v-tabs-window-item>
              </v-tabs-window>
            </div>
          </div>
        </div>
      </v-card>
       <!-- #add -->
       <v-dialog v-model="dialogadd" max-width="800" persistent>
        <v-card title="Agregar donante">
          <v-card-text>
            <div class="text-caption">
              Completa la información del formulario para agregar un nuevo donante. Verifica que esté escrita de manera correcta.
            </div>
            <v-row class="mt-1">
              <v-col>
                <v-text-field v-model="donant.nombres" label="Nombres"/>
                <v-select v-model="donant.tipo_documento" label="Tipo de documento" :items="['Cédula de ciudadanía', 'Cédula de extranjería', 'Pasaporte', 'Número de Identificación Tributaria']"></v-select>
                <v-text-field v-model="donant.correo_personal" label="Correo electrónico personal"/>
              </v-col>
              <v-col>
                <v-text-field v-model="donant.apellidos" label="Apellidos" />
                <v-text-field v-model="donant.documento" label="Documento" />
                <v-text-field v-model="donant.ciudad" label="Ciudad" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text="Cancelar" @click="dialogadd = false"></v-btn>
            <v-btn color="primary" variant="tonal" text="Agregar" :loading="isloading" @click="add"></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- #progress -->
      <v-dialog v-model="isprocess" max-width="320" persistent>
        <v-list class="py-2" color="primary" elevation="12" rounded="lg">
          <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
            <template v-slot:prepend>
              <div class="pe-4">
                <v-icon color="primary" size="x-large"></v-icon>
              </div>
            </template>
            <template v-slot:append>
              <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
            </template>
          </v-list-item>
        </v-list>    
      </v-dialog>
    </v-container>
  </v-main>
</div>