<template src="./views/AuthorizationAvn.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import Donant from '@/models/donant';
  import swal from 'sweetalert';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric, email } from '@vuelidate/validators';
  import NavBar from "@/components/manager/tools/NavBar.vue";
  export default{
    name: 'AuthorizationAvn',
    setup () {
      return { v$: useVuelidate() }
    },
    components: {
      NavBar
    },
    data: () => ({
      isprocess: false,
      setting: new Setting(),
      donant : new Donant(),
      isAgreement: false,
      vinculacion: "Soy un nuevo donante",
      authorization: null,
      ages: [
        {value: '18 - 25', label: '18 - 25 años'},
        {value: '26 - 30', label: '26 - 30 años'},
        {value: '31 - 35', label: '31 - 35 años'},
        {value: '36 - 40', label: '36 - 40 años'},
        {value: '41 - 25', label: '41 - 45 años'},
        {value: '46 - 50', label: '46 - 50 años'},
        {value: '51 - 55', label: '51 - 55 años'},
        {value: '> 55', label: '> 55 años'},
      ],
      formations: [
        {value: 'bachiller', label: 'Bachiller'},
        {value: 'tecnico', label: 'Técnico'},
        {value: 'tecnologo', label: 'Tecnólogo'},
        {value: 'pregrado', label: 'Pregrado'},
        {value: 'posgrado', label: 'Posgrado'},
      ],
      increment: [
        {value: 'fijo', label: 'Fijo anual'},
        {value: 'ipc', label: 'Con el IPC'},
        {value: 'personalizado', label: 'Personalizado'},
        {value: 'ninguno', label: 'Ninguno'},
      ],
      incrementstatic: [
        {value: '10000', label: '$10.000'},
        {value: '15000', label: '$15.000'},
        {value: '20000', label: '$20.000'},
        {value: '25000', label: '$25.000'},
      ],
      canvas: null,
      draw: false,
      newPos: { x: 0, y: 0},
      pos: null,
      ctx: null,
      viewport: window.visualViewport,
      offsetX: 0,
      offsetY: 0,
      ongoingTouches: []
    }),
    mounted(){
      if(!localStorage.getItem('authorization')){
        window.history.back();
      }else{
        this.isprocess = true;
        this.authorization = localStorage.getItem('authorization');
        this.getdata();
        this.init();
      }      
    },
    validations () {
      return {
        donant: {
          edad: { required },
          formacion: { required },
          correo_laboral: { required, email },
          ciudad: { required },
          telefono: { required, numeric },
          comunicacion: { required },
          hijos: { required },
          poder: { required },
          empresa: { required },
          cargo: { required },
          aporte: { required },
          incremento_tipo: { required }
        }
      }
    },
    methods: {

      async getdata(){
        await axios.get(this.setting.params.api + '/donante/datos/' + this.authorization,{
          headers:{ Authorization: localStorage.getItem('token') }
        })
        .then(res => {
          let data = res.data;
          this.isprocess = false;
          if(data.status){
            this.donant.setpersonaldata(data.donante);
            this.vinculacion = data.donante.authorization_file ? "Soy donante actual y quiero actualizar mi aporte mensual" : "Soy donante nuevo y quiero donar mensualmente";
          }else{
            this.showerror({message: 'Error al cargar los datos'});
          }
        });
      },

      init(){
        this.canvas = document.querySelector("#signdraw");
        this.ctx = this.canvas.getContext("2d");
        this.posicion = this.canvas.getBoundingClientRect();
        this.canvas.addEventListener("mousedown", this.startDraw, false);
        this.canvas.addEventListener("mousemove", this.drawLine, false);
        this.canvas.addEventListener("mouseup", this.stopDraw, false);
        this.canvas.addEventListener("touchstart", this.handleStart);
        this.canvas.addEventListener("touchend", this.handleEnd);
        this.canvas.addEventListener("touchcancel", this.handleCancel);
        this.canvas.addEventListener("touchmove", this.handleMove);
      },

      startDraw() {
        this.draw = true;
      },

      drawLine(e) {
        e.preventDefault();
        if(this.draw){
          this.ctx.lineJoin = this.ctx.lineCap = "round";
          this.ctx.lineWidth = 2;
          this.ctx.strokeStyle = "#000";
          this.ctx.beginPath();
          this.ctx.moveTo(this.newPos.x, this.newPos.y);
          this.ctx.lineTo(e.offsetX, e.offsetY); 
          this.ctx.stroke();
        }
        this.newPos.x = e.offsetX;
        this.newPos.y = e.offsetY;
      },

      stopDraw() {
        this.draw = false;
      },

      handleStart(e) {
        e.preventDefault();
        const a = e.changedTouches;
        this.offsetX = this.canvas.getBoundingClientRect().left;
        this.offsetY = this.canvas.getBoundingClientRect().top;
        for (let t = 0; t < a.length; t++) {
          this.ongoingTouches.push(this.copyTouch(a[t]));
        }
      },

      handleMove(e) {
        e.preventDefault();
        const a = e.changedTouches;
        for (let t = 0; t < a.length; t++) {
          const e = "black";
          const o = this.ongoingTouchIndexById(a[t].identifier);
          if(o >= 0){
            this.ctx.beginPath();
            this.ctx.moveTo(this.ongoingTouches[o].clientX - this.offsetX, this.ongoingTouches[o].clientY - this.offsetY);
            this.ctx.lineTo(a[t].clientX - this.offsetX, a[t].clientY - this.offsetY);
            this.ctx.lineWidth = 2;
            this.ctx.strokeStyle = e;
            this.ctx.lineJoin = "round", this.ctx.closePath();
            this.ctx.stroke();
            this.ongoingTouches.splice(o, 1, this.copyTouch(a[t]));
          }
        }
      },

      handleEnd(e) {
        e.preventDefault();
        const a = e.changedTouches;
        for (let t = 0; t < a.length; t++) {
          const e = "black";
          let o = this.ongoingTouchIndexById(a[t].identifier);
          if(o >= 0){
            this.ctx.lineWidth = 2;
            this.ctx.fillStyle = e;
            this.ongoingTouches.splice(o, 1);
          }
        }
      },

      handleCancel(e) {
        e.preventDefault();
        const a = e.changedTouches;
        for (let t = 0; t < a.length; t++) {
          let e = this.ongoingTouchIndexById(a[t].identifier);
          this.ongoingTouches.splice(e, 1);
        }
      },

      copyTouch({identifier: e,clientX: a,clientY: t}) {
        return {identifier: e,clientX: a,clientY: t};
      },

      ongoingTouchIndexById(e) {
        for (let a = 0; a < this.ongoingTouches.length; a++) {
          const t = this.ongoingTouches[a].identifier;
          if (t === e) return a;
        }
        return -1
      },

      cleansign(){
        const canvas = document.getElementById('signdraw');
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      },

      async generate(){
        let validate = await this.v$.donant.$validate();
        if(!validate){
          return;
        }
        try {
          this.isprocess = true;
          this.donant.recaudador = localStorage.getItem('name');
          const sign = document.querySelector("#signdraw").toDataURL("image/png");
          await axios.post(this.setting.params.api + "/admin/donantes/authorization", {image: sign, donante: this.donant, vinculacion: this.vinculacion},{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let data = res.data;
            if(data.status){
              window.open(this.setting.params.api + data.document);
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              }).then(() => {
                window.history.back();
              });
            }else{
              this.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({title: "ERROR",text: error.message,icon: "error", closeOnEsc: false, closeOnClickOutside: false, button: false });
      }

    }
  }
</script>
<style>
@import url('@/assets/css/authorization.css');
</style>