<div class="my-5">
  <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" background-color="#eeeeee" :opacity="0.9">
    <div class="text-center">
      <div>
        <v-progress-circular color="primary" :size="75" indeterminate />
      </div>
      <div class="text-caption">
        {{ overlay_msg }}
      </div>
    </div>
  </loading>
  <div class="d-flex flex-column justify-center">
    <div class="text-center mb-3">
      <div class="transaction-text-primary">¡Sé parte del cambio!</div>
      <div class="transaction-text-secondary">Por favor completa los datos, recibirás una notificación en tu teléfono para completar esta donación a través de Nequi.</div>
    </div>
    <div class="d-flex justify-center my-3">
      <div class="mr-3">
        <v-img src="@/assets/images/logo-nequi.png" width="50"/>
      </div>
      <v-divider vertical />
      <div class="ml-3">
        <v-img src="@/assets/images/wompi_logo.png" width="50" />
      </div>      
    </div>
    <div>
      <v-text-field v-model="transaction.nequi_number" label="Número de Cuenta Nequi" maxlength="10"
      onkeypress="return event.charCode>=48 && event.charCode<=57"
      :error-messages="v$.transaction.nequi_number.$errors.map(e => e.$message)"/>
      <v-checkbox v-model="acceptance">
        <template v-slot:label>
          <div class="text-caption">
            Acepto haber leído los <a target="_blank" :href="transaction.permalink">téminos, condiciones y la política de privacidad</a> para hacer esta transacción.
          </div>
        </template>
      </v-checkbox>
    </div>
    <div>
      <!-- notificaciones -->
      <v-alert closable title="Error" :text="output.error" color="red-darken-2" v-if="output.error"></v-alert>
    </div>
    <div class="mb-auto"></div>
    <div class="mb-3">
      <v-btn color="warning" block rounded="lg" variant="flat" size="large" text="Finalizar" 
      style="color: white !important;bottom: 5px;" :disabled="!acceptance" @click="begintransaction()"/>
      <div class="d-flex align-center">
        <div class="pa-3">
          <v-icon>mdi-security</v-icon>
        </div>
        <div class="transaction-text-legal">
          Esta transacción se realizará con Wompi y cuenta con una certificación PCI DSS para al manejo, transmisión y procesamiento seguro de datos de tarjeta y una encriptación SSL para garantizar la protección de estos.
        </div>
      </div>
    </div>
  </div>
</div>