<div class="my-5">
  <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" background-color="#eeeeee"/>
  <div class="d-flex flex-column justify-center">
    <div class="text-center mb-3">
      <div class="transaction-text-primary">¡Sé parte del cambio!</div>
      <div class="transaction-text-secondary">
        Tus datos personales se usarán para generar tu certificado de donación. Si no los proporcionas o son incorrectos, la donación se registrará como anónima y no podrás recibir el certificado.
      </div>
    </div>
    <div>
      <div class="d-flex">
        <v-text-field v-model="donant.nombres" label="Nombres" 
        :error-messages="v$.donant.nombres.$errors.map(e => e.$message)"
        onkeypress="return (event.charCode>=65 && event.charCode<=90) || (event.charCode>=97 && event.charCode<=122) || event.charCode==32"/>
        <v-text-field v-model="donant.apellidos" label="Apellidos" 
        :error-messages="v$.donant.apellidos.$errors.map(e => e.$message)"
        onkeypress="return (event.charCode>=65 && event.charCode<=90) || (event.charCode>=97 && event.charCode<=122) || event.charCode==32"/>
      </div>
      <div class="d-flex">
        <v-select v-model="donant.tipo_documento" class="w-25" label="Tipo" :items="['CC','NIT']" :error-messages="v$.donant.tipo_documento.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donant.documento" class="w-75" label="Documento" 
        onkeypress="return event.charCode>=48 && event.charCode<=57"
        :error-messages="v$.donant.documento.$errors.map(e => e.$message)"/>
      </div>
      <div>
        <v-text-field v-model="donant.correo_personal" label="Correo electrónico personal" :error-messages="v$.donant.correo_personal.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donant.correo_laboral" label="Correo electrónico laboral" v-if="form.emailwork"/>
        <v-text-field v-model="donant.telefono" label="Número de Contacto" 
        onkeypress="return event.charCode>=48 && event.charCode<=57" :error-messages="v$.donant.telefono.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donant.ciudad" label="Ciudad" v-if="form.city"/>
        <v-text-field v-model="donant.fecha_nacimiento" label="Fecha de nacimiento" type="date" v-if="form.birdthday"/>
        <v-select v-model="donant.edad" label="Rango de edad" :items="['18 - 25','26 - 30','31 - 35','36 - 40','41 - 45','46 - 50','51 - 55','> 55']" v-if="form.age"/>
        <v-select v-model="donant.formacion" label="Nivel de formación" :items="['Bachiller','Técnico','Tecnológo','Pregrado','Posgrado']" v-if="form.formation"/>
        <v-text-field v-model="donant.empresa" label="Empresa" v-if="form.company"/>
        <v-text-field v-model="donant.cargo" label="Cargo" v-if="form.jobs"/>
      </div>
    </div>
    <div>
      <v-checkbox v-model="acceptance">
        <template v-slot:label>
          <div>
            He leído y acepto la <a href="https://unitedwaycolombia.org/wp-content/uploads/2021/04/United-Way-Colombia-Poli%CC%81tica-de-proteccio%CC%81n-de-datos-2020_Revisio%CC%81n_BU-v3.pdf" target="_blank">política de protección de datos personales</a> de United Way Colombia.
          </div>
        </template>
      </v-checkbox>
    </div>
    <div class="mb-auto"></div>
    <div class="my-3">
      <v-btn color="warning" block rounded="lg" variant="flat" size="large" text="Confirmar" 
      style="color: white !important;bottom: 5px;" :disabled="!acceptance" @click="following"/>
      <v-btn class="my-3" block rounded="lg" variant="tonal" size="large" text="Volver" @click="stepcero"/>
    </div>
    <div class="mt-3">
      <v-alert v-if="iserror" color="error" icon="$error" variant="tonal" title="Error" :text="iserror"></v-alert>
    </div>
  </div>
</div>