<template src="./views/DonantDonations.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  export default{
    name: 'DonantDonations',
    props: ['documento'],
    mounted(){
      this.user.status = localStorage.status;
      this.user.type = localStorage.type;
      this.user.name = localStorage.name;
      this.user.token = localStorage.token;
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      user: {
        status: null,
        type: null,
        name: null,
        token: null
      },
      isloading: false,
      isprocess: false,
      search:null,
      headers: [
        { title: 'A'},
        { title: 'Referencia', key: 'referencia' },
        { title: 'CampañaId', key: 'idformulario' },
        { title: 'Entidad', key: 'entidad' },
        { title: 'Tipo', key: 'tipo' },
        { title: 'Valor', key: 'valor' },
        { title: 'Fecha', key: 'fecha' },
        { title: 'Estado', key: 'estado' }
      ],
      donations: []
    }),
    methods: {

      async load(){
        try {
          this.isloading = true;
          await axios.get(this.setting.params.api + '/admin/donaciones/donante/' + this.documento,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then( res => {
            let data = res.data;
            this.isloading = false;
            if(data.status){
              this.donations = data.donaciones;
            }
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async remove(value){
        try {
          let confirm = null;
          await swal({title: "¿eliminar registro?",text: '¿Seguro quiere eliminar este registro?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }
          this.isprocess = true;
          await axios.delete(this.setting.params.api + '/admin/donaciones/delete/' + value, {
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then( res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({
          title: "ERROR",
          text: error.message, 
          icon: "error",
          buttons: {
            text: "ACEPTAR",
          }
        });
      },     

    }
  }
</script>