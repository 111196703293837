<div>
  <v-toolbar title="Estadísticas" density="compact" />
  <div class="d-flex justify-space-evenly  mt-3">
    <v-card class="ma-1">
      <v-card-title>Recaudo por mes</v-card-title>
      <v-card-text>
        <v-skeleton-loader type="card" v-if="isloading"></v-skeleton-loader>
        <Line :data="datactx" :options="optionsctx" v-if="!isloading" height="300px" width="500px"/>
      </v-card-text>
    </v-card>
    <v-card class="ma-1">
      <v-card-title>Recaudo por target</v-card-title>
      <v-card-text>
        <v-skeleton-loader type="card" v-if="isloading"></v-skeleton-loader>
        <Doughnut :data="datactx2" :options="optionsctx2" v-if="!isloading" height="300px" width="500px"/>
      </v-card-text>
    </v-card>
  </div>
</div>