<div class="d-flex flex-column align-center bg-grey-lighten-3 h-100">
  <v-card class="confirm-main">
    <!--header-->
    <div>
      <div class="confirm-line-header"></div>
      <div class="d-flex justify-space-between px-5">
        <v-img src="@/assets/images/logo.png" width="100" style="max-width: 100px;" />
        <v-img src="@/assets/images/wompi_logo.png" width="100" style="max-width: 100px;"/>
      </div>
    </div>
    <v-divider></v-divider>
    <!--content-->
    <div class="confirm-data">
      <div class="position-relative">

        <div v-if="!status" style="min-height: 500px;">
          <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" background-color="#ffffff">
            <div class="text-center">
              <div class="mb-5">
                <v-progress-circular color="primary" :size="75" indeterminate />
              </div>
              <div class="text-caption">Por favor espera...</div>
              <div class="text-caption">No cierres ni recargues esta página.</div>
            </div>
          </loading>
        </div>

        <!--APPROVED-->
        <div v-if="status == 'APPROVED'">
          <div class="d-flex flex-column justify-center pa-5">
            <div class="text-center mb-3">
              <v-icon class="text-h2" color="success">mdi-check-circle-outline</v-icon>
              <div class="transaction-text-primary">Donación Exitosa</div>
              <div class="confirm-text-secondary">¡Muchas gracias por ser parte del cambio!</div>
            </div>
          </div>
          <div class="pa-5">
            <div class="text-caption font-weight-bold">DETALLES DE TU DONACIÓN</div>
            <v-list class="text-caption" lines="one">
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Desde</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.card_brand }} **** **** **** {{ transaction.card_last_four }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Valor donado</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.moneyformat(transaction.amount) }} COP</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Fecha</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.formatdate(transaction.created) }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">No. Referencia</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.reference }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Donado a</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_name }}</div>
                </v-list-item-title>                
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">{{ transaction.merchant_legal_id_type }}</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_legal_id }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <div class="text-center my-3">
              <v-btn variant="text" @click="print">Imprimir comprobante</v-btn>
            </div>
            <div class="text-center mt-5">
              <v-btn class="" color="error" variant="text" prepend-icon="mdi-open-in-new" text="finalizar donación" @click="openlink('https://unitedwaycolombia.org')" />
            </div>
          </div>
        </div>

        <!--DECLINED-->
        <div v-if="status == 'DECLINED'">
          <div class="d-flex flex-column justify-center pa-5">
            <div class="text-center mb-3">
              <v-icon class="text-h2" color="error">mdi-close-circle-outline</v-icon>
              <div class="transaction-text-primary">Donación Rechazada</div>
              <div class="confirm-text-secondary">¡La donación no fue exitosa!</div>
            </div>
          </div>
          <div class="pa-5">
            <div class="text-caption font-weight-bold">DETALLES</div>
            <v-list class="text-caption" lines="one">
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Desde</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.card_brand }} **** **** **** {{ transaction.card_last_four }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Valor</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.moneyformat(transaction.amount) }} COP</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Fecha</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.formatdate(transaction.created) }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">No. Referencia</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.reference }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Donado a</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_name }}</div>
                </v-list-item-title>                
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">{{ transaction.merchant_legal_id_type }}</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_legal_id }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <div class="text-center">
              <v-btn variant="text" @click="print">Imprimir comprobante</v-btn>
            </div>
            <div class="text-center mt-5">
              <v-btn color="error" variant="text" prepend-icon="mdi-open-in-new" text="Intentar nuevamente" @click="$router.push({name: 'landingmain', params: { id: form.id, reference: form.code },})" />
            </div>
          </div>
        </div>

        <!--PENDING-->
        <div v-if="status == 'PENDING'">
          <div class="d-flex flex-column justify-center pa-5">
            <div class="text-center mb-3">
              <v-icon class="text-h2" color="warning">mdi-alert-outline</v-icon>
              <div class="transaction-text-primary">Donación Pendiente</div>
              <div class="confirm-text-secondary">¡Esta donación está sujeta a verificación!</div>
            </div>
          </div>
          <div class="pa-5">
            <div class="text-caption font-weight-bold">DETALLES DE TU DONACIÓN</div>
            <v-list class="text-caption" lines="one">
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Desde</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.card_brand }} **** **** **** {{ transaction.card_last_four }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Valor donado</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.moneyformat(transaction.amount) }} COP</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Fecha</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.formatdate(transaction.created) }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">No. Referencia</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.reference }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Donado a</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_name }}</div>
                </v-list-item-title>                
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">{{ transaction.merchant_legal_id_type }}</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_legal_id }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <div class="text-center my-3">
              <v-btn variant="text" @click="print">Imprimir comprobante</v-btn>
            </div>
            <div class="text-center mt-5">
              <v-btn class="" color="error" variant="text" prepend-icon="mdi-open-in-new" text="finalizar donación" @click="openlink('https://unitedwaycolombia.org')" />
            </div>
          </div>
        </div>

        <!--ERROR-->
        <div v-if="status == 'ERROR'">
          <div class="d-flex flex-column justify-center pa-5">
            <div class="text-center mb-3">
              <v-icon class="text-h2" color="error">mdi-close-circle-outline</v-icon>
              <div class="transaction-text-primary">Error</div>
              <div class="confirm-text-secondary">¡Se ha presentado un error!</div>
              <div class="confirm-text-secondary">No se ha aplicado ningún cargo al medio de pago.</div>
            </div>
          </div>
          <div class="pa-5">
            <div class="text-caption font-weight-bold">DETALLES</div>
            <v-list class="text-caption" lines="one">
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Desde</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.card_brand }} **** **** **** {{ transaction.card_last_four }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Valor a donar</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.moneyformat(transaction.amount) }} COP</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Fecha</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ setting.formatdate(transaction.created) }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">No. Referencia</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.reference }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">Donar a</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_name }}</div>
                </v-list-item-title>                
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  <div class="text-caption">{{ transaction.merchant_legal_id_type }}</div>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <div class="text-caption font-weight-bold">{{ transaction.merchant_legal_id }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <div class="text-center my-3">
              <v-btn variant="text" @click="print">Imprimir comprobante</v-btn>
            </div>
            <div class="text-center my-3">
              <v-btn color="error" variant="text" prepend-icon="mdi-open-in-new" text="Intentar nuevamente" @click="$router.push({name: 'landingmain', params: { id: form.id, reference: form.code },})" />
            </div>
          </div>
        </div>

        <!--FAILED-->
        <div v-if="status == 'FAILED'">
          <div class="d-flex flex-column justify-center pa-5">
            <div class="text-center mb-3">
              <v-icon class="text-h2" color="error">mdi-close-circle-outline</v-icon>
              <div class="transaction-text-primary">Error</div>
              <div class="confirm-text-secondary">¡No se ha podido consultar esta donación!</div>
              <div class="confirm-text-secondary">Estos pueden ser alguno de los motivos:</div>
              <v-list class="text-left" lines="one">
                <v-list-item>
                  <template v-slot:prepend>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                  </template>
                  <v-list-item-title>
                    <div class="confirm-text-secondary">Puede que no este registrada en el sistema.</div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <template v-slot:prepend>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                  </template>
                  <v-list-item-title>
                    <div class="confirm-text-secondary">El comprobante ya ha sido emitido anteriormente.</div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <div class="text-center my-3">
                <v-btn variant="text" @click="print">Imprimir comprobante</v-btn>
              </div>
              <div class="text-center my-3">
                <v-btn color="error" variant="text" prepend-icon="mdi-open-in-new" text="Intentar nuevamente" @click="$router.push({name: 'landingmain', params: { id: form.id, reference: form.code },})" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--footer-->
    <div class="confirm-footer"></div>
  </v-card>
  <div class="text-caption mb-2">uwcolombia.org &#169; 2024</div>
</div>