<div class="h-100">
  <div class="donant-main">
    <div class="donant-picture"></div>
    <div class="donant-form d-flex flex-column justify-center h-100 position-relative">       
      <div class="h-25">
        <v-img class="logo-wide" src="@/assets/images/logo-wide.jpg" />     
      </div>
      <div class="d-flex flex-column justify-center align-center h-50">
        <div class="px-5 w-100">
          <div class="text-center text-h5">Actualización de Datos</div>
          <div class="text-center text-caption">Por favor actualiza los siguientes datos:</div>
          <div class="mt-3">  
            <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false"/>
            <v-row>
              <v-col>
                <v-text-field v-model="donant.nombres" hide-details="auto" label="Nombres" 
                :error-messages="v$.donant.nombres.$errors.map(e => e.$message)" />
              </v-col>
              <v-col>
                <v-text-field v-model="donant.apellidos" hide-details="auto" label="Apellidos" 
                :error-messages="v$.donant.apellidos.$errors.map(e => e.$message)"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="donant.fecha_nacimiento" hide-details="auto" label="Fecha de Nacimiento" type="date" 
                :error-messages="v$.donant.fecha_nacimiento.$errors.map(e => e.$message)"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="donant.correo_personal" hide-details="auto" label="Correo Electrónico Personal" 
                :disabled="!locked"
                :error-messages="v$.donant.correo_personal.$errors.map(e => e.$message)"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="donant.telefono" hide-details="auto" label="Número de Teléfono" 
                onkeypress="return event.charCode>=48 && event.charCode<=57"
                :error-messages="v$.donant.telefono.$errors.map(e => e.$message)"/>
              </v-col>
              <v-col>
                <v-text-field v-model="donant.ciudad" hide-details="auto" label="Ciudad de Residencia" 
                :error-messages="v$.donant.ciudad.$errors.map(e => e.$message)"/>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <v-checkbox v-model="policy">
              <template v-slot:label>
                <div class="text-third">
                  Autorizo a United Way Colombia a realizar tratamiento de mis datos personales, para los fines contemplados en la 
                  <a href="https://unitedwaycolombia.org/wp-content/uploads/2021/04/United-Way-Colombia-Poli%CC%81tica-de-proteccio%CC%81n-de-datos-2020_Revisio%CC%81n_BU-v3.pdf" target="blank">
                    Política de Privacidad para el Tratamiento de Datos Personales
                  </a>
                  de dicha entidad, en la cual se incluyen, entre otras, la realización de piezas de comunicación interna y externa.
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="w-100">
            <v-btn block color="primary" variant="flat" size="large" text="Confirmar mis datos" 
            :disabled="!policy" @click="update()" v-if="!error.state"/>
          </div>
          <div class="mt-3">
            <v-alert v-if="error.state" color="error" icon="$error" variant="tonal" title="Error" :text="error.message"></v-alert>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-end h-25 text-caption">
        &#169; Derechos Reservados UWC 2024
      </div>
    </div>
  </div>
</div>