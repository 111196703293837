<div class="my-5">
  <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" background-color="#eeeeee" :opacity="0.9">
    <div class="text-center">
      <div>
        <v-progress-circular color="primary" :size="75" indeterminate />
      </div>
      <div>
        {{ overlay_msg }}
      </div>
    </div>
  </loading>
  <div class="d-flex flex-column justify-center">
    <div class="text-center mb-3">
      <div class="transaction-text-primary">¡Sé parte del cambio!</div>
      <div class="transaction-text-secondary">Por favor completa los datos y finaliza tu donación.</div>
    </div>
    <div class="d-flex justify-center my-3">
      <div class="mr-3">
        <v-img src="@/assets/images/logo-tarjetas.png" width="50"/>
      </div>
      <v-divider vertical />
      <div class="ml-3">
        <v-img src="@/assets/images/wompi_logo.png" width="50" />
      </div>      
    </div>
    <div class="my-3">
      <v-text-field v-model="transaction.card_holder" label="Nombre del Titular"
      :error-messages="v$.transaction.card_holder.$errors.map(e => e.$message)"/>
      <v-row no-gutters>
        <v-col>
          <v-text-field v-model="transaction.card_number" label="Número de Tarjeta" maxlength="16"
          onkeypress="return event.charCode>=48 && event.charCode<=57"
          :error-messages="v$.transaction.card_number.$errors.map(e => e.$message)"/>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field v-model="transaction.card_cvc" label="CVC" maxlength="3"
          :error-messages="v$.transaction.card_cvc.$errors.map(e => e.$message)"/>
        </v-col>
        <v-col>
          <v-select v-model="transaction.card_exp_month" label="Mes de vencimiento" 
          :items="['01','02','03','04','05','06','07','08','09','10','11','12']"
          :error-messages="v$.transaction.card_exp_month.$errors.map(e => e.$message)"/>
        </v-col>
        <v-col>
          <v-select v-model="transaction.card_exp_year" label="Año de vencimiento" 
          :items="years" item-title="label" item-value="value" 
          :error-messages="v$.transaction.card_exp_year.$errors.map(e => e.$message)"/>
        </v-col>
      </v-row>
      <v-select v-model="transaction.card_installments" label="Número de cuotas" 
      :items="['1','2','3','4','5','6','7','8','9','10','11','12']"
      :error-messages="v$.transaction.card_installments.$errors.map(e => e.$message)"/>
      <v-checkbox v-model="acceptance">
        <template v-slot:label>
          <div class="text-caption">
            Acepto haber leído los <a target="_blank" :href="transaction.permalink">téminos, condiciones y la política de privacidad</a> para hacer esta transacción.
          </div>
        </template>
      </v-checkbox>
    </div>
    <div>
      <!-- notificaciones -->
      <v-alert closable title="Error" :text="output.error" color="red-darken-2" v-if="output.error"></v-alert>
    </div>
    <div class="mb-auto"></div>
    <div class="mb-5">
      <v-btn color="warning" block rounded="lg" variant="flat" size="large" text="Finalizar" 
      style="color: white !important;bottom: 5px;" :disabled="!acceptance" @click="begintransaction()"/>
      <div class="d-flex align-center">
        <div class="pa-3">
          <v-icon>mdi-security</v-icon>
        </div>
        <div class="transaction-text-legal">
          Esta transacción se realizará con Wompi y cuenta con una certificación PCI DSS para al manejo, transmisión y procesamiento seguro de datos de tarjeta y una encriptación SSL para garantizar la protección de estos.
        </div>
      </div>
    </div>
  </div>
</div>