<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="bg-white pa-5" :elevation="2">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-credit-card-clock</v-icon>
              <div class="ml-3 text-h4">Módulo Donaciones Recurrentes</div>
            </div>
            <div>
              Gestión de donaciones recurrentes.
            </div>
          </div>
        </div>
        <div class="mt-5">
          <v-toolbar density="compact">
            <v-toolbar-title>Registro de donantes recurrentes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field prepend-inner-icon="mdi-magnify" density="compact" :elevation="0" label="Search" variant="outlined" hide-details single-line/>
            <v-btn icon @click="loadforms()">
              <v-icon>mdi-cursor-move</v-icon>
            </v-btn>
            <v-btn icon @click="">
              <v-icon>mdi-credit-card-clock</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" :items="data" :search="search">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-btn icon :elevation="0" size="small" @click="halt(item.id)" v-if="item.estado">
                    <v-tooltip text="desactivar"  activator="parent" location="bottom" />
                    <v-icon >mdi-alarm-off</v-icon>
                  </v-btn>

                  <v-btn icon :elevation="0" size="small" @click="activate(item.id)" v-if="!item.estado">
                    <v-tooltip text="activar"  activator="parent" location="bottom" />
                    <v-icon >mdi-alarm</v-icon>
                  </v-btn>

                  <v-btn icon :elevation="0" size="small" @click="remove(item.id)">
                    <v-tooltip text="eliminar"  activator="parent" location="bottom" />
                    <v-icon >mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <td class="text-left">{{ item.nombres }}</td>
                <td class="text-left">{{ item.apellidos }}</td>
                <td class="text-left">{{ item.correo_personal }}</td>
                <td class="text-left">{{ item.titulo }}</td>
                <td class="text-left">{{ item.fuente }}</td>
                <td class="text-left">{{ setting.moneyformat(item.valor) }}</td>
                <td class="text-center">{{ item.alta }}</td>
                <td class="text-center">{{ item.proximociclo }}</td>
                <td class="text-center">
                  <v-chip class="text-caption" :color="setting.setcolours(item.estado)">
                    <div v-if="item.estado">activo</div>
                    <div v-else>inactivo</div>
                  </v-chip>
                </td>
                <td class="text-right">{{ item.created_at }}</td>
                <td class="text-right">{{ item.updated_at }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
  </v-main>

  <!-- #dialogtransfer -->
  <v-dialog v-model="dialogtransfer" max-width="500" persistent>
    <v-card title="Cambiar destino recurrencia">
      <v-card-text>
        <div class="text-caption">Por favor selecciona el formulario de origen y el formulario de destino.</div>
        <div class="mt-3">
          <v-select v-model="transfersetting.origin" label="Formulario de origen (abierta)"
          :items="formspublished" item-title="titulo" item-value="id"/>
          <v-select v-model="transfersetting.destiny" label="Formulario de destino (cerrada)" 
          :items="formsclosed" item-title="titulo" item-value="id"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogtransfer = !dialogtransfer" />
        <v-btn color="primary" variant="tonal" text="Transferir" @click="transfer()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #enddialog -->

  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
  <!-- #endregion -->
</div>