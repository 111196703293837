<template src="./views/TransactionMain.html"></template>
<script>
  import axios from 'axios';
  import Setting from '@/models/setting';
  import Form from '@/models/form';
  import ModulePay from './ModulePay.vue';
  import ModuleData from './ModuleData.vue';
  import ModulePayment from './ModulePayment.vue';
  import ZonePse from './ZonePse.vue';
  import ZoneCard from './ZoneCard.vue';
  import ZoneTransfer from './ZoneTransfer.vue';
  import ZoneNequi from './ZoneNequi.vue';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';

  export default{
    name: 'TransactionMain',
    components: {
      Loading,
      ModulePay,
      ModuleData,
      ModulePayment,
      ZonePse,
      ZoneCard,
      ZoneTransfer,
      ZoneNequi
    },
    mounted(){
      this.form.id = this.$route.params.id;
      this.form.code = this.$route.params.reference;
      localStorage.formid = this.$route.params.id;
      localStorage.formcode = this.$route.params.reference;
      this.load();
    },
    data: () => ({
      setting : new Setting(),
      form : new Form(),
      overlay: true,
      transaction: null,
      donant: null,
      step: 0,
      process: 25,    
      isState: null,
      isFinish: false
    }),

    methods: {
      async load() {
        try {
          await axios.get(this.setting.params.api + '/admin/landing/form/get/'+ this.form.id)
          .then(res => {
            let result = res.data;
            this.form.setdata(result.formulario[0]);
          });
          this.cover();
        } catch (error) {
          this.setting.showerror(error);
        }
      },

      async cover(){
        try {
          await axios.get(this.setting.params.api + '/admin/landing/form/cover/'+ this.form.cover)
          .then(res => {
            const a = document.getElementById("transaction-banner-cover");
            a.style.backgroundImage = `url('${res.data}')`;
            this.overlay = false;
          });
        } catch (error) {
          this.setting.showerror(error);
        }
      },

      stepcero(){
        this.step = 0;
        this.process = 25;
      },

      stepone(transaction){
        this.transaction = transaction;
        this.step = 1;
        this.process = 50;
      },

      steptwo(donant){
        this.donant = donant;
        this.step = 2;
        this.process = 75;
      },

      stepthree(method){
        this.transaction.payment_method_type = method;
        this.step = 3;
        this.process = 100;
      }

    }
  }
</script>
<style>
  @import '@/assets/css/transactions.css';
</style>