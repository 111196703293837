<template src="./AccessDonants.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  export default{
    name: 'AccessDonants',
    setup () {
      return { v$: useVuelidate() }
    },
    data: () => ({
      setting : new Setting(),
      isloading: false,
      document: null,
      error: {
        state: false,
        message: ''
      }
    }),
    validations () {
      return {
        document:{ required }
      }
    },
    methods: {

      async validate(){
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }
        try {
          this.isloading = true;
          await axios.get(this.setting.params.api + '/donante/validar/' + this.document)
          .then(res => {
            this.isloading = false;
            let result = res.data;
            if(result.status){
              localStorage.donantdocument = this.document;
              switch (result.data.account_state) {
                case 'active':
                localStorage.donantstate = 'active';
                localStorage.donantauthorized = true;
                localStorage.token = 'donante';
                this.$router.push({name: "donantvalidate"});
                  break;
                case 'suspended':
                  this.error.message = 'La cuenta se encuentra suspendida, por favor contacta a soporte.';
                  this.error.state = true;
                  break;
                default:
                this.$router.push({name: "donantupdate"});
                  break;
              }
            }else{
              this.error.message = 'El número de documento enviado no se encuentra registrado en el sistema.';
              this.error.state = true;
            }
          });
        } catch (error) {
          this.isloading = false;
          this.error.message = error.message;
          this.error.state = true;
        }
      }

    }
  }
</script>
<style>
  @import '@/assets/css/donants.css';
</style>