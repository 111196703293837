<template src="./views/CertificateGenerate.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  import moment from 'moment';
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';
  export default{
    name:'CertificateGenerate',
    mounted(){
      if(!localStorage.getItem('token')){
        this.$router.push({ name: 'home' });
      }else{
        this.year = this.$route.params.year;
        this.document = this.$route.params.document;
        this.build();
      }
    },
    data: () => ({
      setting: new Setting(),
      isprocess: true,
      title: 'Por favor espera...',
      year: null,
      document: null,
      template: '',
      certificate: {
        nombres: null,
        apellidos: null,
        documento: null,
        referencia : null,
        numero : null,
        fecha : null,
        valornumero : 0,
        valorletra : null,
      },
      timeemit: null,
    }),
    methods:{

      async build(){
        let confirm = await this.gettemplate();
        if(!confirm){
          this.showerror({ message: 'Error al cargar la plantilla!' });
          return;
        }
        
        confirm = await this.getdata();
        if(!confirm){
          this.showerror({ message: 'Error al cargar los datos!' });
          return;
        }

        try {
          document.getElementById('vigencia').innerHTML = this.year;
        } catch {
          console.log('Not vigence');
        }

        try {
          document.getElementById('nombres').innerHTML = this.certificate.nombres;
        } catch {
          console.log('Not name');
        }

        try {
          document.getElementById('apellidos').innerHTML = this.certificate.apellidos;
        } catch {
          console.log('Not lastname');
        }

        try {
          document.getElementById('documento').innerHTML = this.certificate.documento;
        } catch {
          console.log('Not document');
        }

        try {
          document.getElementById('donacion').innerHTML = this.certificate.valornumero;
        } catch {
          console.log('Not value');
        }

        try {
          document.getElementById('letras').innerHTML = this.certificate.valorletra;
        } catch {
          console.log('Not letters');
        }

        await this.setdate();

        await this.generate();
        this.isprocess = false;
        window.history.back();
      },

      async gettemplate(){
        try {
          this.title = 'Cargando plantilla...';
          return new Promise(resolve => {
            axios.get(this.setting.params.api + '/admin/plantillas/get/'+this.year, {
              headers:{ Authorization: localStorage.getItem('token') }
            })
            .then(res => {
              let data = res.data;
              if(data.status){
                this.template = data.template;
                resolve(true);  
              }
              resolve(false);
            }).catch(() => {
              resolve(false);
            });
          });
          
        } catch (error) {
          this.showerror(error);
        }
      },

      async getdata(){
        try {
          this.title = 'Obtener datos...';
          return new Promise(resolve => {
            let frm = new FormData;
            frm.append('documento', this.document);
            frm.append('vigencia', this.year);
            axios.post(this.setting.params.api + '/admin/donantes/certificate', frm, {
              headers:{ Authorization: localStorage.getItem('token') }
            })
            .then(res => {
              let data = res.data;
              if(data.status){
                this.certificate = data.certificado;
                resolve(true);
              }
              resolve(false);
            })
            .catch(() => {
              resolve(false);
            });
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async setdate(){
        this.title = 'Generando salida...';
        return new Promise(resolve => {
          moment.locale('es'); 
          this.timeemit = moment().format('DD [de] MMMM [de] YYYY');
          setTimeout(function(){
            resolve(true);
          },1000);
        });        
      },

      async generate(){
        return new Promise(resolve => {
          html2canvas(document.querySelector('#canva'))
          .then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const doc = new jsPDF('p','px','letter');
            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, 'PNG', 0, 0, width,height);
            doc.save('certificado.pdf');
          });
          resolve(true);
        });
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({title: "ERROR",text: error.message,icon: "error", closeOnEsc: false, closeOnClickOutside: false, button: false });
      }

    }
  }
</script>
<style>
  @import '@/assets/css/certificate.css';
</style>