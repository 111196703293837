<template src="./views/ZonePse.html"></template>
<script>
  import Setting from '@/models/setting';
  import Donant from '@/models/donant';
  import Donation from '@/models/donation';
  import Transaction from '@/models/transaction';
  import axios from 'axios';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';

  export default{
    name: 'ZonePse',
    setup () {
      return { v$: useVuelidate() }
    },
    components: { Loading },
    props: { donant: Donant, transaction: Transaction },
    mounted(){
      this.getfinancial();
    },
    data: () => ({
      setting: new Setting,
      donation: new Donation,
      acceptance: false,
      banklist: [],
      overlay: true,
      output: {
        error: null,
        message: 'Cargando entidades financieras.'
      },
      integrity: null,
      reference: null,
      state: true
    }),
    validations () {
      return{
        transaction: {
          pse_user_type: { required: helpers.withMessage('Requerido.', required) },
          pse_institution_code: { required: helpers.withMessage('Requerido.', required) }
        }
      }
    },
    methods:{

      async getfinancial(){
        await axios.get(this.setting.params.wompi + '/pse/financial_institutions',{
          headers: { accept: "application/json", Authorization: "Bearer " + this.setting.params.key }
        }).then(r => {
          this.banklist = r.data.data;
        }).catch(() => {
          this.output.error = 'No se han podido cargar las entidades financieras disponibles.';
        });
        this.overlay = false;
      },

      async begintransaction(){
        if(!await this.v$.$validate()){
          return;
        }

        this.output.message = 'Iniciando transacción...';
        this.overlay = true;

        //Donante
        try {
          await axios.post(this.setting.params.api + '/donacion/transacciones/add/donate', this.donant)
          .then(r => {
            if(r.data.status){
              this.donation.donante = r.data.data;
              this.donation.formulario = this.transaction.form;
              this.donation.valor = this.transaction.amount;
              this.donation.centavos = this.transaction.amount_in_cents;
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 501.";
              this.state = false;
            }
          });
          this.output.message = 'Donante registrado...';
        } catch (error) {
          this.overlay = false;
          this.output.error = error.message;
          return;
        }

        if(!this.state){return;}

        //Donación
        try {
          this.output.message = 'Creando donación...';
          await axios.post(this.setting.params.api + '/donacion/transacciones', this.donation)
          .then(r => {
            if(r.data.status){
              this.reference = r.data.reference;
              this.integrity = r.data.integrity;
            }else{
              this.overlay = false;
              this.output.error = "Error al procesar la donación. Código 502.";
              this.state = false;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }

        if(!this.state){return;}

        //procesar transacción con wompi
        this.output.message = 'Abriendo plataforma PSE...';
        let it = null; //idtransaction
        const datatransaction = {
          "public-key": this.setting.params.key,
          "acceptance_token": this.transaction.acceptance_token,
          "amount_in_cents": this.transaction.amount_in_cents,
          "signature": this.integrity,
          "reference": this.reference,
          "currency": "COP",
          "customer_email": this.donant.correo_personal,
          "payment_method": {
            "type": this.transaction.payment_method_type,
            "user_type": this.transaction.pse_user_type,
            "user_legal_id_type": this.donant.tipo_documento,
            "user_legal_id": this.donant.documento,
            "financial_institution_code": this.transaction.pse_institution_code,
            "payment_description": "Donación a Fundación United Way Colombia, ref: " + this.reference
          },
          "redirect_url": this.setting.params.redir,
          "customer_data": {
            "phone_number": this.donant.telefono,
            "full_name": this.donant.nombres + " " + this.donant.apellidos
          }
        }

        try {
          await axios.post(this.setting.params.wompi + '/transactions', datatransaction,{
            headers: { accept: "application/json", Authorization: "Bearer " + this.setting.params.key }
          }).then(r => {
            if(r.data){
              it = r.data.data.id;
            }else{
              this.state = false;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error = error.message;
          return;
        }

        if(!this.state){
          this.overlay = false;
          this.error = 'Error al intentar realizar la transacción. Código 504.';
          return;
        }

        if(it){
          await this.delay(5000);
          this.confirm(it);
        }else{
          this.overlay = false;
          this.error = 'Error al obtener respuesta de transacción. Código 509.';
        }

      },

      async confirm(t){
        await axios.get(this.setting.params.wompi + '/transactions/' + t.toString())
        .then(r => {
          this.overlay = false;
          window.location.assign(r.data.data.payment_method.extra.async_payment_url);
        });
      },

      delay(timeout){
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, timeout);
        });
      }

    }
  }
</script>