<template src="./ManagerForms.html"></template>
<script>
  import NavBar from '../tools/NavBar.vue';
  import FormsCharts from './FormsCharts.vue';
  import FormsList from './FormsList.vue';
  export default{
    name: 'ManagerForms',
    components:{
      NavBar,
      FormsList,
      FormsCharts
    }

  }
</script>