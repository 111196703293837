<div class="container">
  <div class="d-flex align-center justify-space-between">
    <div class="text-h5">Perfil</div>
    <div>
      <v-btn-toggle color="primary" variant="outlined">
        <v-btn icon @click="isedit = true">
          <v-tooltip text="editar perfil" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="generateauthorization" v-if="user.status">
          <v-tooltip text="generar autorización" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-clipboard-edit-outline</v-icon>
        </v-btn>
        <v-btn icon @click="downloadfileavn" v-if="donante.autorizacion_file">
          <v-tooltip text="descargar autorización" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn icon @click="dialogauthorization = true" v-if="user.status">
          <v-tooltip text="cargar autorización" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn icon @click="dialogdonation = true" v-if="user.status">
          <v-tooltip text="crear donación" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
        <v-btn icon @click="setperiods">
          <v-tooltip text="certficado donación" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-file-certificate-outline</v-icon>
        </v-btn>
        <v-btn icon @click="refreshtoken" v-if="user.status">
          <v-tooltip text="reestablecer token" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="remove" v-if="user.status">
          <v-tooltip text="eliminar donante" activator="parent" location="bottom"></v-tooltip>
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
  <div class="mt-3 mb-3">
    <v-row>
      <v-col>
        <v-text-field v-model="donante.nombres" label="Nombres" :disabled="!isedit" :error-messages="v$.donante.nombres.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donante.tipo_documento" label="Tipo documento" disabled />
        <v-text-field v-model="donante.correo_personal" label="Correo electrónico personal" :disabled="!isedit" :error-messages="v$.donante.correo_personal.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donante.telefono" label="Número de teléfono" :disabled="!isedit" :error-messages="v$.donante.telefono.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donante.fecha_nacimiento" label="Fecha de nacimiento" disabled />
        <v-text-field v-model="donante.edad" label="Rango de edad" disabled />
        <v-text-field v-model="donante.empresa" label="empresa" disabled />
      </v-col>
      <v-col>
        <v-text-field v-model="donante.apellidos" label="Apellidos" :disabled="!isedit" :error-messages="v$.donante.apellidos.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donante.documento" label="Número de documento" disabled />
        <v-text-field v-model="donante.correo_laboral" label="Correo electrónico laboral" :disabled="!isedit" :error-messages="v$.donante.correo_laboral.$errors.map(e => e.$message)"/>
        <v-text-field v-model="donante.ciudad" label="Ciudad" disabled />
        <v-text-field v-model="donante.hijos" label="Hijos" disabled />
        <v-text-field v-model="donante.formacion" label="Nivel de formación" disabled />
        <v-text-field v-model="donante.cargo" label="Cargo" disabled />
      </v-col>
    </v-row>
    <div class="text-right" v-if="isedit">
      <v-btn class="mr-3" color="amber" @click="update">Actualizar</v-btn>
      <v-btn @click="isedit=false">Cancelar</v-btn>
    </div>
  </div>
  <!-- #region -->
  <v-dialog v-model="dialogdonation" max-width="500" persistent>
    <v-card title="Nueva donación">
      <v-card-text>
        <div class="text-caption">Por favor complete los sigientes datos.</div>
        <div>
          <v-text-field v-model="donation.fecha" label="Fecha" type="date" />
          <v-text-field v-model="donation.referencia" label="Referencia" />
          <v-text-field v-model="donation.entidad" label="Entidad" />
          <v-radio-group v-model="donation.tipo" inline>
            <v-radio label="PLATAFORMA" value="PLATAFORMA"></v-radio>
            <v-radio label="AVN" value="AVN"></v-radio>
            <v-radio label="DIGITAL" value="DIGITAL"></v-radio>
          </v-radio-group>
          <v-text-field v-model="donation.valor" label="Valor" onkeypress="return event.charCode>=48 && event.charCode<=57"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogdonation = false"></v-btn>
        <v-btn color="primary" variant="tonal" text="Agregar" :loading="isloading" @click="adddonation"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #region -->
  <v-dialog v-model="dialogauthorization" max-width="500" persistent>
    <v-card title="Cargar autorización">
      <v-card-text>
        <div class="text-caption">Por favor complete los sigientes datos.</div>
        <div class="mt-2">
          <v-file-input v-model="fileavn" label="Autorización Descuento AVN" accept="application/pdf"></v-file-input>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogauthorization = false"></v-btn>
        <v-btn color="primary" variant="tonal" text="Cargar" :loading="isloading" @click="uploadfileavn"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #region -->
  <v-dialog v-model="dialogcertificate" max-width="500" persistent>
    <v-card title="Vigencia">
      <v-card-text>
        <div class="text-caption">Por favor seleccione la vigencia a certificar.</div>
        <v-select v-model="period" label="Vigencia" :items="itemperiods" item-title="fecha" item-value="fecha"></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogcertificate = false"></v-btn>
        <v-btn color="primary" variant="tonal" text="Generar" :loading="isloading" @click="generatecertificate"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
</div>