<div class="border h-100">
  <v-toolbar title="Campañas ocultas" density="compact" />
  <v-data-table :headers="headers" :items="data" :loading="isloading" density="compact" hide-default-footer>
    <template v-slot:item="{ item }">
      <tr>
        <td>
          <v-btn icon :elevation="0" size="small" @click="hide(item.id)">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </td>
        <td>{{ item.id }}</td>
        <td>{{ item.nombre }}</td>
        <td>{{ item.estado }}</td>
      </tr>
    </template>
  </v-data-table>
</div>