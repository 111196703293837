<template src="./AccessManager.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import { useVuelidate } from '@vuelidate/core';
  import { email, required } from '@vuelidate/validators';
  export default{
    name: 'AccessManager',
    setup () {
      return { v$: useVuelidate() }
    },
    mounted(){
      
    },
    data: () => ({
      setting : new Setting(),
      show: false,
      userdata: {
        email: null,
        password: null
      },
      iserror: null,
      isloading: false,
      isprocess: false,
    }),
    validations () {
      return {
        userdata:{
          email: { required, email },
          password: { required }
        }
      }
    },
    methods:{
      async signin(){
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }
        try {
          this.iserror = null;
          this.isloading = true;
          axios.post(this.setting.params.api + '/admin/singin',this.userdata)
          .then(res => {
            this.isloading = false;
            let data = res.data;
            if(data.status){
              localStorage.status = data.status;
              localStorage.type = data.tipo;
              localStorage.name = data.name;
              localStorage.token = data.token;
              this.$router.push({ name: 'managerdonants' });
            }else{
              this.iserror = data.message;
            }
          })
          .catch(error => {
            this.isloading = false;
            this.iserror = error.message;
          });
        } catch (error) {
          this.isloading = false;
          this.iserror = error.message;
        }        
      }
    }
  }
</script>