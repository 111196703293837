<div class="h-auto mt-5">
  <div class="d-flex flex-column justify-center h-auto">
    <div class="text-center mb-3">
      <div class="transaction-text-primary">¡Sé parte del cambio!</div>
      <div class="transaction-text-secondary">Por favor selecciona la fuente de pago para hacer tu donación.</div>
    </div>
    <div class="my-3">
      <v-card class="mb-3" rounded="lg" @click="set('CARD')"
      :class="{'bg-secondary': method == 'CARD'}">
        <div class="d-flex align-center pa-5">
          <div class="mr-5">
            <v-img src="@/assets/images/logo-tarjetas.png" width="50" />
          </div>
          <div class="text-h6">Tarjeta Crédito / Débito</div>
        </div>
      </v-card>
      <v-card class="mb-3" rounded="lg" @click="set('PSE')"
      :class="{'bg-secondary': method == 'PSE'}" v-if="!transaction.recurrent">
        <div class="d-flex align-center pa-5">
          <div class="mr-5">
            <v-img src="@/assets/images/logo-pse.png" width="50" />
          </div>
          <div class="text-h6">Cuenta de ahorros o corriente</div>
        </div>
      </v-card>
      <v-card class="mb-3" rounded="lg" @click="set('BANCOLOMBIA_TRANSFER')"
      :class="{'bg-secondary': method == 'BANCOLOMBIA_TRANSFER'}" v-if="!transaction.recurrent">
        <div class="d-flex align-center pa-5">
          <div class="mr-5">
            <v-img src="@/assets/images/logo-bancolombia.png" width="50" />
          </div>
          <div class="text-h6">Cuenta Bancolombia</div>
        </div>
      </v-card>
      <v-card class="mb-3" rounded="lg" @click="set('NEQUI')"
      :class="{'bg-secondary': method == 'NEQUI'}">
        <div class="d-flex align-center pa-5">
          <div class="mr-5">
            <v-img src="@/assets/images/logo-nequi.png" width="50" />
          </div>
          <div class="text-h6">Cuenta Nequi</div>
        </div>
      </v-card>
    </div>
    <div class="mb-auto"></div>
    <div v-if="method">
      <v-btn color="warning" block rounded="lg" variant="flat" size="large" text="Continuar" style="color: white !important;bottom: 5px;" @click="following()"/>
    </div>
    <div class="my-3">
      <v-btn block rounded="lg" variant="tonal" size="large" text="Volver" @click="stepcero"/>
    </div>
  </div>
</div>