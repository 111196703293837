<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="bg-white pa-5" :elevation="2">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-swap-vertical-bold</v-icon>
              <div class="ml-3 text-h4">
                Módulo Datos Masivos
              </div>
            </div>
            <div>
              Exportar e importar datos másivos de donantes y donaciones.
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-row>
            <v-col cols="4">
              <v-toolbar title="Importar / Exportar" />
              <v-card class="mt-5" title="Importar" variant="outlined">
                <v-card-text>
                  <v-btn class="mt-3" color="primary" size="large" variant="tonal" 
                  prepend-icon="mdi-upload" block text="Importar donantes" @click="dialogdonants=true"/>
                  <v-btn class="mt-3" color="primary" size="large" variant="tonal" 
                  prepend-icon="mdi-upload" block text="Importar donaciones" @click="dialogdonations=true"/>
                </v-card-text>
              </v-card>
              <v-card class="mt-5" title="Exportar" variant="outlined">
                <v-card-text>
                  <v-text-field v-model="exportsetting.begin" label="Ingrese fecha desde" type="date" :error-messages="v$.exportsetting.begin.$errors.map(e => e.$message)"/>
                  <v-text-field v-model="exportsetting.end" label="Ingrese fecha hasta" type="date" :error-messages="v$.exportsetting.end.$errors.map(e => e.$message)"/>
                  <v-select v-model="exportsetting.type" label="Seleccione objeto para exportar" :items="['donantes','donaciones']" :error-messages="v$.exportsetting.type.$errors.map(e => e.$message)"></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="w-25 mr-3" color="primary" size="large" variant="tonal" text="Generar" :loading="isloading" @click="exportdata"></v-btn>
                  <download-excel :data="donants" type="xlsx" worksheet="users" name="registros_donantes.xlsx" v-if="donants">
                    <v-btn color="primary" size="large" variant="text" >Registros_donantes.csv</v-btn>
                  </download-excel>
                  <download-excel :data="donations" type="xlsx" worksheet="users" name="registros_donaciones.xlsx" v-if="donations">
                    <v-btn color="primary" size="large" variant="text">Registros_donaciones.csv</v-btn>
                  </download-excel>                  
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-toolbar>
                <v-toolbar-title>Campañas Cerradas</v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="d-flex w-50 mr-3">
                  <v-text-field v-model="searchvalue" append-inner-icon="mdi-magnify" density="compact" :elevation="0" label="Search" variant="outlined" hide-details single-line @click:append-inner="searchlanding"/>
                  <download-excel :data="landing" type="xlsx" worksheet="landing" name="registros_landing_cerradas.xlsx" >
                    <v-btn class="mx-3" icon :elevation="0" @click="downloadclosed">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </download-excel>
                </div>
              </v-toolbar>
              <v-data-table :headers="headers" :items="landingfind">
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <download-excel 
                      :data="landingdetails" 
                      type="xlsx" 
                      worksheet="users" 
                      name="registros_landing_closed.xlsx">
                        <v-btn icon :elevation="0" @click="datalanding(item.id)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </download-excel>
                    </td>
                    <td>{{ item.referencia }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.estado }}</td>
                    <td>{{ setting.formatdate(item.created_at) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </v-main>

  <!-- #region -->
  <v-dialog v-model="dialogdonants" max-width="500" persistent>
    <v-card title="Importar donantes">
      <v-card-text>
        <div class="text-caption">Por favor complete los sigientes datos.</div>
        <div class="mt-3">
          <v-file-input v-model="filedonants" label="Importar donantes" 
          :error-messages="v$.filedonants.$errors.map(e => e.$message)"/>
          <v-btn variant="text" text="Descargar plantilla" @click="downloadtemplate('template_donantes.csv')"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogdonants = false"></v-btn>
        <v-btn color="primary" variant="tonal" text="Importar" :loading="isloading" @click="importdonants"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #region -->
  <v-dialog v-model="dialogdonations" max-width="500" persistent>
    <v-card title="Importar donaciones">
      <v-card-text>
        <div class="text-caption">Por favor complete los sigientes datos.</div>
        <div class="mt-3">
          <v-file-input v-model="filedonations" label="Importar donaciones" 
          :error-messages="v$.filedonations.$errors.map(e => e.$message)"/>
          <v-btn variant="text" text="Descargar plantilla" @click="downloadtemplate('template_donaciones.csv')"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Cancelar" @click="dialogdonations = false"></v-btn>
        <v-btn color="primary" variant="tonal" text="Importar" :loading="isloading" @click="importdonations"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
</div>