<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="pa-5">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-file-pdf-box</v-icon>
              <div class="ml-3 text-h4">
                Módulo Plantillas Certificados
              </div>
            </div>
            <div>
              Gestionar plantillas de certificados de donaciones.
            </div>
          </div>
          <div>
            <div class="d-flex">
              <v-btn class="mr-3" icon :elevation="0" @click="dialogadd = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="d-flex">
            <div class="ma-3" v-for="template in templates">
              <v-btn prepend-icon="mdi-file-pdf-box" size="x-large" @click="get(template.id)">{{ template.id }}</v-btn>
            </div>
          </div>
        </div>
      </v-card>
      <!-- #region -->
      <v-dialog v-model="dialogadd" max-width="500" persistent>
        <v-card title="Nueva plantilla">
          <v-card-text>
            <div class="text-caption">
              Completa la información del formulario para agregar un nuevo donante. Verifica que esté escrita de manera correcta.
            </div>
            <div class="mt-1">
              <v-text-field v-model="templateadd.id" label="Etiqueta"/>
              <div class="text-caption">Etiquetas: nombres, apellidos, documento, donacion, vigencia, letras</div>
              <v-textarea v-model="templateadd.data" label="Plantilla"/>
          </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text="Cancelar" @click="dialogadd = false"></v-btn>
            <v-btn color="primary" variant="tonal" text="Agregar" :loading="isloading" @click="add"></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- #endregion -->
    
      <!-- #region -->
      <v-dialog v-model="dialogupdate" max-width="500" persistent>
        <v-card title="Actualizar plantilla">
          <v-card-text>
            <div class="text-caption">
              Completa la información del formulario para agregar un nuevo donante. Verifica que esté escrita de manera correcta.
            </div>
            <div class="mt-1">
              <v-text-field v-model="templateupdate.id" label="Etiqueta"/>
              <div class="text-caption">Etiquetas: nombres, apellidos, documento, donacion, vigencia, letras</div>
              <v-textarea v-model="templateupdate.data" label="Plantilla"/>
          </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text="Cancelar" @click="dialogupdate = false"></v-btn>
            <v-btn color="warning" variant="tonal" text="Actualizar" :loading="isloading" @click="update"></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- #endregion -->
    
      <!-- #progress -->
      <v-dialog v-model="isprocess" max-width="320" persistent>
        <v-list class="py-2" color="primary" elevation="12" rounded="lg">
          <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
            <template v-slot:prepend>
              <div class="pe-4">
                <v-icon color="primary" size="x-large"></v-icon>
              </div>
            </template>
            <template v-slot:append>
              <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
            </template>
          </v-list-item>
        </v-list>    
      </v-dialog>
    </v-container>
  </v-main>
</div>