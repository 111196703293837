<div>
  <v-toolbar density="compact">
    <v-toolbar-title>Formularios de recaudo</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="dialogadd = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-toolbar>
  <v-data-table :headers="headers" :items="data" :loading="isloading">
    <template v-slot:item="{ item }">
      <tr>
        <td>
          <v-btn icon :elevation="0" size="small" @click="copyto(item.id,item.codigo)" v-if="item.estado == 'published'">
            <v-tooltip text="enlace"  activator="parent" location="bottom"></v-tooltip>
            <v-icon>mdi-link</v-icon>
          </v-btn>

          <v-btn icon :elevation="0" size="small" @click="embedto(item.id,item.codigo)" v-if="item.estado == 'published'">
            <v-tooltip text="incrustar"  activator="parent" location="bottom"></v-tooltip>
            <v-icon>mdi-code-braces</v-icon>
          </v-btn>

          <v-btn icon :elevation="0" size="small" @click="open(item.id)">
            <v-tooltip text="abrir"  activator="parent" location="bottom"></v-tooltip>
            <v-icon>mdi-open-in-app</v-icon>
          </v-btn>

          <v-btn icon :elevation="0" size="small" @click="close(item.id)" v-if="item.donaciones && item.estado == 'published'">
            <v-tooltip text="cerrar"  activator="parent" location="bottom"></v-tooltip>
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn icon :elevation="0" size="small" @click="remove(item.id)" v-if="!item.donaciones">
            <v-tooltip text="borrar"  activator="parent" location="bottom"></v-tooltip>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </td>
        <td class="text-left">{{ item.id }}</td>
        <td class="text-left">{{ item.codigo }}</td>
        <td class="text-left">{{ item.objetivo }}</td>
        <td class="text-center">{{ item.donaciones }}</td>
        <td class="text-right">{{ setting.moneyformat(item.recaudo) }}</td>
        <td class="text-right">
          <v-chip class="text-caption" :color="setting.setcolours(item.estado)">
            {{ item.estado }}
          </v-chip>
        </td>
        <td class="text-right">{{ item.created_at }}</td>
      </tr>
    </template>
  </v-data-table>

  <!-- #region -->
  <v-dialog v-model="dialogadd" transition="dialog-bottom-transition" fullscreen persistent>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="dialogadd = false" />
        <v-toolbar-title>Nuevo formulario de recaudo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text="Agregar" variant="text" @click="add" />
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div class="text-caption">Por favor complete los sigientes datos.</div>
        <v-row>
          <v-col cols="6">
            <div class="text-h5">Datos</div>
          </v-col>
          <v-col>
            <div class="text-h5">Campos obligatorios</div>
          </v-col>
          <v-col>
            <div class="text-h5">Campos opcionales</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="form.begin" label="Desde" type="date"
            :error-messages="v$.form.begin.$errors.map(e => e.$message)"/>
            <v-text-field v-model="form.title" label="Nombre del formulario"
            :error-messages="v$.form.title.$errors.map(e => e.$message)"/>
            <v-text-field v-model="form.target" label="Público objetivo (target)"
            :error-messages="v$.form.target.$errors.map(e => e.$message)"/>
            <v-text-field v-model="form.btn1" label="Valor del primero botón (obligatorio)" 
            :error-messages="v$.form.btn1.$errors.map(e => e.$message)"
            onkeypress="return event.charCode>=48 && event.charCode<=57"/>
            <v-text-field v-model="form.btn2" label="Valor del segundo botón (opcional)" onkeypress="return event.charCode>=48 && event.charCode<=57"/>
            <v-text-field v-model="form.btn3" label="Valor del tercer botón (opcional)" onkeypress="return event.charCode>=48 && event.charCode<=57"/>
            <v-text-field v-model="form.btn4" label="Valor del cuarto botón (opcional)" onkeypress="return event.charCode>=48 && event.charCode<=57"/>
          </v-col>
          <v-col>
            <v-text-field v-model="form.end" label="Hasta" type="date"
            :error-messages="v$.form.end.$errors.map(e => e.$message)"/>
            <v-text-field v-model="form.subtitle" label="Subtítulo del formulario"
            :error-messages="v$.form.subtitle.$errors.map(e => e.$message)"/>
            <v-file-input v-model="coverfile" label="Portada del formulario"/>
            <v-text-field v-model="form.msg1" label="Mensaje de salida primer botón"/>
            <v-text-field v-model="form.msg2" label="Mensaje de salida segundo botón"/>
            <v-text-field v-model="form.msg3" label="Mensaje de salida tercer botón"/>
            <v-text-field v-model="form.msg4" label="Mensaje de salida cuarto botón"/>
          </v-col>
          <v-col>
            <v-checkbox :model-value="true" label="Nombres" disabled  />
            <v-checkbox :model-value="true" label="Apellidos" disabled  />
            <v-checkbox :model-value="true" label="Tipo de documento" disabled  />
            <v-checkbox :model-value="true" label="Documento" disabled  />
            <v-checkbox :model-value="true" label="Correo electrónico personal" disabled  />
            <v-checkbox :model-value="true" label="Número télefono" disabled  />
          </v-col>
          <v-col>
            <v-checkbox v-model="form.emailwork" label="Correo electrónico laboral"></v-checkbox>
            <v-checkbox v-model="form.city" label="Ciudad de residencia"></v-checkbox>
            <v-checkbox v-model="form.birdthday" label="Fecha de nacimiento"></v-checkbox>
            <v-checkbox v-model="form.age" label="Rango edad"></v-checkbox>
            <v-checkbox v-model="form.formation" label="Nivel de formación"></v-checkbox>
            <v-checkbox v-model="form.company" label="Empresa"></v-checkbox>
            <v-checkbox v-model="form.jobs" label="Cargo"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- #endregion -->

  <!-- #snack -->
  <v-snackbar v-model="snackbar.state">
    {{ snackbar.text }}
  </v-snackbar>
  <!-- #endsnack -->

  <!-- #dialogembed -->
  <v-dialog v-model="dialogembed" max-width="500" persistent>
    <v-card title="Incrustar formulario">
      <v-card-text>
        <v-textarea v-model="codeembed" label="Código"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="tonal" text="Aceptar" @click="dialogembed = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #enddialogembed -->

  <!-- #dialogopen -->
  <v-dialog v-model="dialogopen" transition="dialog-bottom-transition" fullscreen persistent>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="dialogopen = false" />
        <v-toolbar-title>Detalle Donaciones</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table :headers="headerdonations" :items="donations">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.referencia }}</td>
              <td>{{ item.documento }}</td>
              <td>{{ item.nombres }}</td>
              <td>{{ item.apellidos }}</td>
              <td>{{ item.correo_personal }}</td>
              <td>{{ item.telefono }}</td>
              <td>{{ item.entidad }}</td>
              <td>{{ item.tipo }}</td>
              <td>{{ setting.moneyformat(item.valor) }}</td>
              <td>{{ item.fecha }}</td>
              <td>
                <v-chip class="text-caption" :color="setting.setcolours(item.estado)">
                  {{ item.estado }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- #enddialogopen -->

  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
  <!-- #endregion -->

</div>