<template src="./views/ModulePayment.html"></template>
<script>
  import Transaction from '@/models/transaction';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  export default {
    name: 'ModulePayment',
    setup () {
      return { v$: useVuelidate() }
    },
    props: { transaction: Transaction },
    data: () => ({
      method: null
    }),
    validations () {
      return {
        method: { required }
      }
    },
    methods:{

      set(type){
        this.method = type;
      },

      async following(){
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }
        this.$emit('stepthree',this.method);
      },

      stepcero(){
        this.$emit('stepcero',false);
      }

    }
  }
</script>