

export default class Form {

  constructor(){
    this.id = null;
    this.code = null;
    this.idlanding = null;
    this.title = null;
    this.subtitle = null;
    this.target = null;
    this.cover = null;
    this.begin = null;
    this.end = null;
    this.btn1 = null;
    this.btn2 = null;
    this.btn3 = null;
    this.btn4 = null;
    this.msg1 = null;
    this.msg2 = null;
    this.msg3 = null;
    this.msg4 = null;
    this.emailwork = null;
    this.city = null;
    this.birdthday = null;
    this.age = null;
    this.formation = null;
    this.company = null;
    this.jobs = null;
  }

  setdata(data){
    this.code       = data.codigo ? data.codigo : null;
    this.idlanding  = data.idlanding ? data.idlanding : null;
    this.title      = data.titulo ? data.titulo : null;
    this.subtitle   = data.subtitulo ? data.subtitulo : null;
    this.target     = data.objetivo ? data.objetivo : null;
    this.cover      = data.portada ? data.portada : null;
    this.begin      = data.inicio ? data.inicio : null;
    this.end        = data.cierre ? data.cierre : null;
    this.btn1       = data.button_pr ? data.button_pr : null;
    this.btn2       = data.button_sc ? data.button_sc : null;
    this.btn3       = data.button_th ? data.button_th : null;
    this.btn4       = data.button_fr ? data.button_fr : null;
    this.msg1       = data.message_pr ? data.message_pr : null;
    this.msg2       = data.message_sc ? data.message_sc : null;
    this.msg3       = data.message_th ? data.message_th : null;
    this.msg4       = data.message_fr ? data.message_fr : null;
    this.emailwork  = data.emailwork ? data.emailwork : null;
    this.city       = data.city ? data.city : null;
    this.birdthday  = data.birdthday ? data.birdthday : null;
    this.age        = data.age ? data.age : null;
    this.formation  = data.formation ? data.formation : null;
    this.company    = data.company ? data.company : null;
    this.jobs       = data.job ? data.job : null;
  }

}