<template src="./ManagerLanding.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import NavBar from '../tools/NavBar.vue';
  import LeadView from './LeadView.vue';
  import LandingHide from './LandingHide.vue';
  import LandingVisible from './LandingVisible.vue';

  export default{
    name: 'ManagerLanding',
    components:{
      NavBar,
      LeadView,
      LandingHide,
      LandingVisible
    },
    data: () => ({
      setting: new Setting(),
      search: null,
      isprocess: false,
      landinghide: [],
      landingview: [],
      leads: []
    }),
    mounted(){
      this.loadhides();
    },
    methods: {

      async loadhides(){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api+'/');
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      visibletohide(){
        this.$refs.landinghide.load();
      },

      hidetovisible(){
        this.$refs.landingvisible.load();
      }

    }
  }
</script>