<template src="./ManagerData.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  import NavBar from '../tools/NavBar.vue';
  import JsonExcel from "vue-json-excel3";
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  export default{
    name: 'ManagerData',
    components: {
      NavBar,
      downloadExcel:JsonExcel
    },
    setup () {
      return { v$: useVuelidate() }
    },
    data: ()=>({
      setting: new Setting(),
      isprocess: false,
      isloading: false,
      dialogdonants: false,
      dialogdonations: false,
      headers: [
        { title: 'A', key: 'idusuario'},
        { title: 'Referencia', key: 'referencia' },
        { title: 'Nombres', key: 'nombre' },
        { title: 'Estado', key: 'estado' },
        { title: 'Creado', key: 'created_at' },
      ],
      donants: null,
      donations: null,
      landing: null,
      exportsetting:{
        type: null,
        begin: null,
        end: null
      },
      searchvalue: null,
      landingfind: [],
      landingdetails: [],
      filedonants: [],
      filedonations: []
    }),
    validations () {
      return {
        exportsetting:{ 
          type: { required },
          begin: { required },
          end: { required }
        },
        filedonants: { required },
        filedonations: { required }
      }
    },
    methods: {
      
      async exportdata(){
        let validate = await this.v$.exportsetting.$validate();
        if(!validate){
          return;
        }
        try {
          this.isloading = true;
          this.donants = null;
          this.donations = null;
          await axios.post(this.setting.params.api+'/admin/'+this.exportsetting.type+'/getrange',this.exportsetting,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isloading = false;
            if(data.status){
              if(this.exportsetting.type === 'donantes'){
                this.donants = data.donantes;
              }else{
                this.donations = data.donaciones;
              }
            }
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async searchlanding(){
        if(!this.searchvalue){
          return
        }
        try {
          this.isprocess = true;
          await axios.post(this.setting.params.api+'/admin/landing/search',{input: this.searchvalue},{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let data = res.data;
            if(data.status){
              this.landingfind = data.landing;
            }else{
              this.setting.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async datalanding(landing){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api + '/admin/landing/load/close/'+landing,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              this.landingdetails = data.donaciones;
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async downloadtemplate(template){
        await axios.get('/assets/templates/'+template, {responseType: 'arraybuffer'}).then(res=>{
          let blob = new Blob([res.data], {type:'application/*'})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = template;
          link._target = 'blank'
          link.click();
        })
      },

      async importdonants(){
        let validate = await this.v$.filedonants.$validate();
        if(!validate){
          return;
        }
        try {
          this.dialogdonants = false;
          this.isprocess = true;
          const frm = new FormData;
          frm.append('file',this.filedonants[0]);
          await axios.post(this.setting.params.api+'/admin/donantes/import',frm,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              this.setting.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async importdonations(){
        let validate = await this.v$.filedonations.$validate();
        if(!validate){
          return;
        }
        try {
          this.dialogdonations = false;
          this.isprocess = true;
          const frm = new FormData;
          frm.append('file',this.filedonations[0]);
          await axios.post(this.setting.params.api + '/admin/donaciones/import', frm, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              this.setting.showerror({message: data.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

      async downloadclosed(){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api + '/admin/landing/load/closed',{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            const result = res.data;
            if(result.status){
              this.landing = result.data;
            }else{
              this.landing = null;
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      }

    }
  }
</script>