<template src="./views/ModulePay.html"></template>
<script>
  import Setting from '@/models/setting';
  import axios from 'axios';
  import Transaction from '@/models/transaction';
  import Form from '@/models/form';
  import { useVuelidate } from '@vuelidate/core';
  import { required, minValue, helpers } from '@vuelidate/validators';

  export default {
    name: 'ModulePay',
    setup () {
      return { v$: useVuelidate() }
    },
    props: {form: Form},
    mounted(){
      this.getpermalink();
    },
    data: () => ({
      transaction : new Transaction(),
      setting : new Setting(),
      other : false,
    }),
    validations () {
      return {
        transaction: { 
          amount: { 
            required: helpers.withMessage('Requerido.', required),
            minValue: helpers.withMessage('El valor mínimo es de $10.000 COP', minValue(10000))
          }  
        }
      }
    },
    methods:{

      async getpermalink(){
        await axios.get(this.setting.params.wompi + '/merchants/'+this.setting.params.key)
        .then(res => {
          this.overlay = false;
          let result = res.data.data;
          this.transaction.acceptance_token = result.presigned_acceptance.acceptance_token;
          this.transaction.permalink = result.presigned_acceptance.permalink;
          this.transaction.setmerchant(result);
        });
        this.transaction.amount = this.form.btn1;
      },

      setamount(value){
        this.other = false;
        this.transaction.amount = value;
      },

      setcustomamount(){
        this.other = !this.other;
        this.transaction.amount = null;
      },

      async following(){
        if(this.transaction.amount === null){
          this.transaction.amount = this.form.btn1;
        }
        this.transaction.amount_in_cents = this.transaction.amount * 100;
        this.transaction.form = this.form.id;
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }
        this.$emit('stepone',this.transaction);
      }

    }
  }
</script>