<template src="./LeadView.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  export default{
    name: 'LeadView',
    mounted(){
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      search: null,
      isloading: false,
      headers: [
        {title: "Referencia", align: "start", value: "referencia" }, 
        {title: "NUIP", align: "start", value: "documento" }, 
        {title: "Nombres", align: "start", value: "nombres" }, 
        {title: "Apellidos", align: "start", value: "apellidos"}, 
        {title: "Valor", align: "end", value: "valor" }, 
        {title: "Estado", align: "end", value: "estado"}, 
        {title: "Creado", align: "end", value: "created_at"}
      ],
      data: []
    }),
    methods: {

      async load(){
        try {
          this.isloading = true;
          await axios.get(this.setting.params.api+'/admin/donaciones/latest',{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isloading = false;
            let result = res.data;
            this.data = result.donaciones;
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      }
      
    }
  }
</script>