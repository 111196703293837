<template src="./NavBar.html"></template>
<script>
  export default{
    name: 'NavBar',
    mounted(){
      if(!localStorage.token){
        this.$router.push({ name: 'home' });
      }
    },
    data: () => ({
      isprocess: false,
    }),
    methods:{

      signout(){
        this.isprocess = true;
        localStorage.removeItem('status');
        localStorage.removeItem('type');
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        this.$router.push({ name: 'home' });
      }

    }
  }
</script>