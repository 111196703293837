<template src="./views/ModuleData.html"></template>
<script>
  import Donant from '@/models/donant';
  import Form from '@/models/form';
  import Setting from '@/models/setting';
  import { useVuelidate } from '@vuelidate/core';
  import { required, numeric, minLength, maxLength, email, helpers } from '@vuelidate/validators';
  import axios from 'axios';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';

  const letters = helpers.regex(/^[a-zA-Z ]*$/);

  export default{
    name: 'ModuleData',
    setup () {
      return { v$: useVuelidate() }
    },
    props:{ form: Form },
    components:{
      Loading
    },
    data: () => ({
      setting: new Setting,
      donant: new Donant,
      acceptance: false,
      iserror: null,
      overlay: false,
      
    }),
    validations () {
      return {
        donant: { 
          nombres: { 
            required: helpers.withMessage('Campo es requerido.', required),
            letters: helpers.withMessage('Solo letras.', letters)
          },
          apellidos: { 
            required: helpers.withMessage('Campo es requerido.', required),
            letters: helpers.withMessage('Solo letras.', letters)
          },
          tipo_documento: { required: helpers.withMessage('Campo es requerido.', required) },
          documento: { 
            required: helpers.withMessage('Campo es requerido.', required), 
            numeric: helpers.withMessage('Solo admite números.', numeric), 
            minLength: helpers.withMessage(() => 'Mínimo 6 caracteres.',  minLength(6)), 
            maxLength: helpers.withMessage(() => 'Máximo 15 caracteres.', maxLength(15))
          },
          correo_personal: { 
            required: helpers.withMessage('Campo es requerido.', required), 
            email: helpers.withMessage('Formato inválido.', email) 
          },
          telefono: { 
            required: helpers.withMessage('Campo es requerido.', required), 
            numeric: helpers.withMessage('Solo admite números.', numeric),
          }
        }
      }
    },
    methods:{

      async following(){
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }
        this.overlay = true;
        await axios.post(this.setting.params.api + '/donacion/transacciones/add/donate', this.donant)
        .then(res => {
          let result = res.data;
          if(result.status){
            this.overlay = false;
            this.donant.id = result.data;
            this.$emit('steptow',this.donant);
          }else{
            this.iserror = result.message;
          }
        });
      },

      stepcero(){
        this.$emit('stepcero',false);
      }

    }
  }
</script>