<template src="./ManagerTemplate.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  import NavBar from '../tools/NavBar.vue';
  export default{
    name: 'ManagerTemplate',
    components: {
      NavBar
    },
    mounted(){
      if(!localStorage.getItem('token')){
        this.$router.push({ name: 'home' });
      }else{
        this.load();
      }
    },
    data: () => ({
      setting: new Setting(),
      isloading: false,
      isprocess: false,
      dialogadd: false,
      dialogupdate: false,
      templates: [],
      templateadd: {
        id: null,
        data: null
      },
      templateupdate:{
        id: null,
        data: null
      }
    }),
    methods: {

      async load(){
        this.isprocess = true;
        try {
          await axios.get(this.setting.params.api + '/admin/plantillas/load',{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              this.templates = data.templates;
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
        
      },

      async add(){
        this.isloading = true;
        try {
          await axios.post(this.setting.params.api + '/admin/plantillas/add', this.templateadd,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            let data = res.data;
            this.isloading = false;
            this.dialogadd = false;
            if(data.status){
              swal({title: "success",text: data.message, icon: "success",
                buttons: {text: "ACEPTAR",}
              });
              this.load();
            }else{
              swal({title: "warning",text: data.message, icon: "warning",
                buttons: {text: "ACEPTAR",}
              });
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async get(id){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api + '/admin/plantillas/get/'+id,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              this.templateupdate = data.template;
              this.dialogupdate = true;
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async update(){
        this.isloading = true;
        try {
          await axios.post(this.setting.params.api + '/admin/plantillas/add', this.templateupdate,{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            let data = res.data;
            this.isloading = false;
            this.dialogupdate = false;
            if(data.status){
              swal({title: "success",text: data.message, icon: "success",
                buttons: {text: "ACEPTAR",}
              });
              this.load();
            }else{
              swal({title: "warning",text: data.message, icon: "warning",
                buttons: {text: "ACEPTAR",}
              });
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({
          title: "ERROR",
          text: error.message, 
          icon: "error",
          buttons: {
            text: "ACEPTAR",
          }
        });
      }, 

    }
  }
</script>