<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="bg-white pa-5" :elevation="2">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-bullhorn-outline</v-icon>
              <div class="ml-3 text-h4">
                Módulo Campañas
              </div>
            </div>
            <div>
              Gestión de campañas y formularios de donaciones.
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-row>
            <v-col cols="4">
              <LandingHide v-on:hidetovisible="hidetovisible" ref="landinghide" />
            </v-col>
            <v-col cols="8">
              <LandingVisible v-on:visibletohide="visibletohide" ref="landingvisible" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <LeadView />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </v-main>
</div>