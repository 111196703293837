<div class="bg-grey-lighten-2 h-100">
  <div id="canva" class="screen-certificate bg-white mt-3 mb-3 pa-15">
    <div class="text-caption text-center mt-3 pt-15">referencia {{ certificate.referencia }}</div>
    <div class="text-h6 text-center">CERTIFICADO DE DONACIÓN No {{ certificate.numero }}</div>
    <div class="text-center mt-5">
      Los suscritos, Representante Legal y Revisor Fiscal, respectivamente, de la <b>FUNDACIÓN UNITED WAY COLOMBIA</b> certifican:
    </div>
    <div class="text-justify mt-10" v-html="template.data">
      
    </div>
    <div class="text-center text-caption mt-3">
      Se expide en Bogotá D.C. el {{ timeemit }}
    </div>
  </div>


  <!-- #region -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" :title="title">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
  <!-- #endregion -->
</div>