export default class Transaction{

  constructor(){
    this.id = null;
    this.form = null;
    this.recurrent = false;
    this.reference = null;
    this.amount = 0;
    this.amount_in_cents = null;
    this.payment_method_type = null;
    this.payment_method = null;
    this.currency = 'COP';
    this.acceptance_token = null;
    this.permalink = null;
    this.merchant_name = null;
    this.merchant_contact_name = null;
    this.merchant_phone_number = null;
    this.merchant_legal_name = null;
    this.merchant_logo_url = null;
    this.merchant_legal_id = null;
    this.merchant_legal_id_type = null;
    this.merchant_email = null;
    this.card_number = null;
    this.card_cvc = null;
    this.card_exp_month = null;
    this.card_exp_year = null;
    this.card_holder = null;
    this.card_token = null;
    this.card_brand = null;
    this.card_type = null;
    this.card_last_four = null;
    this.card_name = null;
    this.card_installments = null;
    this.pse_user_type = null;
    this.pse_institution_code = null;
    this.nequi_number = null;
    this.coustumer_email = null;
    this.coustumer_name = null;
    this.coustumer_legal_id = null;
    this.coustumer_legal_id_type = null;
    this.created = null;
  }

  setmerchant(merchant){
    this.merchant_name = merchant.name;
    this.merchant_legal_name = merchant.legal_name;
    this.merchant_contact_name = merchant.contact_name;
    this.merchant_phone_number = merchant.phone_number;
    this.merchant_logo_url = merchant.logo_url;
    this.merchant_legal_id_type = merchant.legal_id_type;
    this.merchant_email = merchant.email;
  }

  setcard(card){
    this.card_name = card.name;
    this.card_token = card.id;
    this.card_brand = card.brand;
    this.card_last_four = card.last_four;
  }

  setnequi(card){
    this.card_token = card.id;
  }

  setcardresult(data){
    this.amount = data.amount_in_cents / 100;
    this.amount_in_cents = data.amount_in_cents;
    this.currency = data.currency;
    this.merchant_legal_id = data.merchant.legal_id;
    this.merchant_legal_id_type = data.merchant.legal_id_type;
    this.merchant_legal_name = data.merchant.legal_name;
    this.merchant_name = data.merchant.name;
    this.reference = data.reference;
    this.card_brand = data.payment_method.extra.brand;
    this.card_type = data.payment_method.extra.card_type;
    this.card_last_four = data.payment_method.extra.last_four;
    this.created = data.created_at
  }

}