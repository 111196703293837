export default class Donant{

  constructor(){
    this.id = null;
    this.nombres = null;
    this.apellidos = null;
    this.tipo_documento = null;
    this.documento = null;
    this.correo_laboral = null;
    this.correo_personal = null;
    this.telefono = null;
    this.ciudad = null;
    this.fecha_nacimiento = null;
    this.hijos = null;
    this.edad = null;
    this.formacion = null;
    this.comunicacion = null;
    this.poder = null;
    this.empresa = null;
    this.cargo = null;
    this.aporte = 0;
    this.incremento_tipo = null;
    this.incremento_valor = null;
    this.recaudaror = null;
    this.tipo = null;
    this.autorizacion_file = null;
    this.autorizacion_tipo = null;
    this.recaudador = null;
    this.account_state = null;
    this.created_at = null;
  }

  setpersonaldata($data){
    this.nombres = $data.nombres;
    this.apellidos = $data.apellidos;
    this.tipo_documento = $data.tipo_documento;
    this.documento = $data.documento;
    this.correo_laboral = $data.correo_laboral;
    this.correo_personal = $data.correo_personal;
    this.telefono = $data.telefono;
    this.ciudad = $data.ciudad;
    this.fecha_nacimiento = $data.fecha_nacimiento;
    this.edad = $data.edad;
    this.empresa = $data.empresa;
    this.hijos = $data.hijos;
    this.formacion = $data.formacion;
    this.cargo = $data.cargo;
    this.autorizacion_file = $data.autorizacion_file;
  }

}