<template src="./LandingHide.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  export default{
    name: 'LandingHide',
    mounted(){
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      search: null,
      isloading: false,
      headers: [
        {title: "A", align: "start", value: "id" }, 
        {title: "IdLanding", align: "start", value: "id" }, 
        {title: "Campaña", align: "start", value: "nombre" }, 
        {title: "Estado", align: "start", value: "estado"}, 
      ],
      data: []
    }),
    expose: ['load'],
    methods: {
      
      async load(){
        try {
          await axios.get(this.setting.params.api+'/admin/landing/load/hide',{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isloading = false;
            let result = res.data;
            this.data = result.data;
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async hide(landing){
        try {
          this.isprocess = true;
          await axios.put(this.setting.params.api + '/admin/landing/change',{id: landing, state: 'open'},{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message, icon: "success",
                buttons: {text: "ACEPTAR",}
              });
              this.load();
              this.$emit('hidetovisible');
            }else{
              swal({title: "warning",text: result.message, icon: "warning",
                buttons: {text: "ACEPTAR",}
              });
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      }

    }
  }
</script>