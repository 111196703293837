<template src="./ManagerRecurrences.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  import NavBar from '../tools/NavBar.vue';
  export default{
    name: 'ManagerRecurrences',
    components: {
      NavBar
    },
    mounted(){
      this.load();
    },
    data: () => ({
      setting: new Setting(),
      search: null,
      isloading: false,
      isprocess: false,
      dialogtransfer: false,
      headers: [
        {title: "A", align: "center", value: "id" }, 
        {title: "Nombres", align: "start", value: "nombres" }, 
        {title: "Apellidos", align: "start", value: "apellidos" }, 
        {title: "Correo Electrónico", align: "start", value: "correo_personal" }, 
        {title: "Formulario", align: "start", value: "titulo" }, 
        {title: "Fuente de Pago", align: "start", value: "fuente" }, 
        {title: "Donación", align: "start", value: "valor" }, 
        {title: "Ciclo Inicio", align: "center", value: "alta" }, 
        {title: "Ciclo Próximo", align: "center", value: "proximociclo" }, 
        {title: "Estado", align: "center", value: "estado" }, 
        {title: "Creado", align: "end", value: "created_at" }, 
        {title: "Actualizado", align: "end", value: "updated_at" }, 
      ],
      data: [],
      transfersetting: {
        origin: null,
        destiny: null
      },
      formspublished: [],
      formsclosed: []
    }),
    methods: {

      async load(){
        try {
          this.isloading = true;
          await axios.get(this.setting.params.api + '/admin/donaciones/recurrentes/load', {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isloading = false;
            let result = res.data;
            if(result.status){
              this.data = result.recurrentes;
            }
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async loadforms(){
        try {
          this.isprocess = true;
          await axios.get(this.setting.params.api + "/admin/landing/form/all/load",{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then((res => {
            this.isprocess = false;
            let result = res.data;
            result.status ?
            (this.formspublished = result.published, this.formsclosed = result.close, this.dialogtransfer = true)
            : this.setting.showerror({message: result.message});
          }));
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async temp(){
        this.isprocess = true;
        await axios.get(this.api + "/admin/donaciones/recurrentes/dailytemp", {
          headers:{ Authorization: localStorage.getItem('token') }
        }).then(res => {
        console.log(res.data)
        });
        this.isprocess = false;
      },

      async activate(e){
        try {
          this.isprocess = true;
          await axios.put(this.setting.params.api + "/admin/donaciones/recurrentes/activate", {value: e}, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status) {
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async halt(e){
        try {
          this.isprocess = true;
          await axios.put(this.setting.params.api + "/admin/donaciones/recurrentes/stop", {value: e}, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status) {
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }
          });
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async transfer(){
        try {
          this.dialogtransfer = false;
          this.isprocess = true;
          await axios.put(this.setting.params.api + "/admin/donaciones/recurrentes/transfer", this.transfersetting, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false; 
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.setting.showerror({message: result.message});
            }
          })
        } catch (error) {
          this.isloading = false;
          this.setting.showerror(error);
        }
      },

      async remove(e){
        try {
          let confirm = null;
          await swal({title: "¿eliminar registro?",text: '¿Seguro quiere eliminar este registro?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }
          this.isprocess = true;
          await axios.delete(this.setting.params.api + '/admin/donaciones/recurrentes/delete/'+ e, {
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isprocess = false;
            let result = res.data;
            if(result.status){
              swal({title: "success",text: result.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      },

    }
  }
</script>