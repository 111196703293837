<template src="./DonantHome.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import Donant from '@/models/donant';
  import NavBar from '@/components/donants/tools/NavBar.vue';
  import DonantProfile from '@/components/commons/DonantProfile.vue';
  import DonantDonation from '@/components/commons/DonantDonation.vue';
  export default{
    name: 'DonantHome',
    components: {
      NavBar,
      DonantProfile,
      DonantDonation
    },
    mounted(){
      if(localStorage.donantauthorized && localStorage.donantdocument){
        this.donant.documento = localStorage.donantdocument;
        this.load();
      }else{
        this.$router.push({ name: 'home' });
      }
    },
    data: () => ({
      isprocess: true,
      tab: 1,
      donant : new Donant(),
      setting: new Setting(),
    }),
    methods: {

      async load(){
        try {
          await axios.post(this.setting.params.api + '/donante/get', {documento: this.donant.documento})
          .then(res => {
            let result = res.data;
            this.isprocess = false;
            if(result.status && result.donante.length){
              this.donant.setpersonaldata(result.donante[0]);
            }else{
              this.setting.showerror({message: result.message});
            }
          });
        } catch (error) {
          this.isprocess = false;
          this.setting.showerror(error);
        }
      }

    }
  }
</script>