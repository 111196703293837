<div class="h-100">
  <div class="donant-main">
    <div class="donant-picture"></div>
    <div class="donant-form d-flex flex-column justify-center h-100 position-relative">       
      <div class="h-25">
        <v-img class="logo-wide" src="@/assets/images/logo-wide.jpg" />     
      </div>
      <div class="d-flex flex-column justify-center align-center h-50">
        <div class="px-5 w-100">
          <div class="text-center text-h5">Verificar cuenta</div>
          <div class="text-left text-caption">Se ha enviado un código de 6 dígitos al correo electrónico, por favor ingresalo y válida tu cuenta.</div>
          <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" :enforce-focus="true"/>
          <div>
            <v-text-field v-model="token" label="Código de acceso" hide-details="auto"
            onkeypress="return event.charCode>=48 && event.charCode<=57"
            :error-messages="v$.token.$errors.map(e => e.$message)"/>
          </div>
          <div class="mt-3">
            <v-btn block color="primary" variant="flat" size="large" text="Validar cuenta" @click="validate()"/>
          </div>
          <div class="mt-3">
            <v-alert v-if="error.state" color="error" icon="$error" variant="tonal" title="Error" :text="error.message"></v-alert>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-end h-25 text-caption">
        &#169; Derechos Reservados UWC 2024
      </div>
    </div>
  </div>
</div>