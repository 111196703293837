<template src="./ValidateToken.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting';
  import { useVuelidate } from '@vuelidate/core';
  import { numeric, required, minLength, maxLength } from '@vuelidate/validators';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  export default{
    name: 'ValidateToken',
    components: {
      Loading
    },
    setup () {
      return { v$: useVuelidate() }
    },
    mounted(){
      if(localStorage.donantdocument){
        this.document = localStorage.donantdocument;
        this.send();
      }else{
        this.$router.push({name:'home'});
      }
    },
    data: () => ({
      setting: new Setting(),
      overlay: true,
      token: null,
      document: null,
      error: {
        state: false,
        message: null
      }
    }),
    validations () {
      return{
        token: { required, numeric, minLength:minLength(6), maxLength: maxLength(6) }
      }
    },
    methods: {

      async send(){
        try {
          await axios.get(this.setting.params.api + '/donante/token/get/' + this.document)
          .then(res => {
            this.overlay = false;
            let result = res.data;
            if(!result.status){
              this.error.message = result.message;
              this.error.state = true;
            }
          }).catch(err => {
            console.log(err);
          });
          
        } catch (error) {
          this.overlay = false;
          this.error.message = error.message;
          this.error.state = true;
        }        
      },

      async validate(){
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }

        try {
          this.overlay = true;
          await axios.put(this.setting.params.api+ '/donante/token/validate',{documento: this.document, token: this.token})
          .then( res => {
            let result = res.data;
            if(result.status){
              localStorage.donantstate = 'active';
              localStorage.donantauthorized = true;
              localStorage.token = 'donante';
              this.$router.push({name:'donanthome'});
            }else{
              this.overlay = false;
              this.error.message = 'Token no válido.';
              this.error.state = true;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error.message = error.message;
          this.error.state = true;
        }
      }

    }
  }
</script>