<template src="./ManagerUsers.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting.js';
  import swal from 'sweetalert';
  import moment from 'moment';
  import JsonExcel from "vue-json-excel3";
  import { useVuelidate } from '@vuelidate/core';
  import { required, email } from '@vuelidate/validators';
  import NavBar from '../tools/NavBar.vue';
  export default{
    name: 'ManagerUser',
    components: {
      NavBar,
      downloadExcel:JsonExcel
    },
    setup () {
      return { v$: useVuelidate() }
    },
    mounted(){
      if(!localStorage.getItem('token')){
        this.$router.push({ name: 'home' });
      }else{
        this.load();
      }
    },
    data: () => ({
      setting: new Setting(),
      isloading: false,
      isprocess: false,
      dialogadd: false,
      search:null,
      headers: [
        { title: 'A', key: 'idusuario'},
        { title: 'Nombres', key: 'nombre' },
        { title: 'Correo Electrónico', key: 'correo' },
        { title: 'Tipo', key: 'tipo' },
        { title: 'Estado', key: 'estado' },
        { title: 'Creado', key: 'created_at' },
        { title: 'Actualizado', key: 'updated_at' }
      ],
      users: [],
      users_export:{
        "id":"idusuario",
        "nombre":"nombre",
        "correo":"correo",
        "tipo":"tipo",
        "creado":"created_at"
      },
      user: {
        nombre: null,
        correo: null,
        token: null,
        tipo: null
      }
    }),
    validations () {
      return {
        user:{ 
          nombre: { required },
          correo: { required, email },
          token: { required },
          tipo: { required },
        }
      }
    },
    methods: {

      async load(){
        try {
          this.isloading = true;
          this.isprocess = true;
          await axios.get(this.setting.params.api + '/admin/usuarios/load',{
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then(res => {
            this.isloading = false;
            this.isprocess = false;
            let data = res.data;
            if(data.status){
              this.users = data.users;
            }
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async add(){
        let validate = await this.v$.user.$validate();
        if(!validate){
          return;
        }
        this.isloading = true;
        try {
          await axios.post(this.setting.params.api + '/admin/usuarios/add', this.user,{
            headers:{ Authorization: localStorage.getItem('token') }
          }).then(res => {
            this.isloading = false;
            this.dialogadd = false;
            let data = res.data;
            if(data.status){
              swal({title: "success",text: data.message, icon: "success",
                buttons: {text: "ACEPTAR",}
              });
              this.load();
            }else{
              swal({title: "warning",text: data.message, icon: "warning",
                buttons: {text: "ACEPTAR",}
              });
            }
          })
          .catch(error => {
            this.showerror(error);
          });
        } catch (error) {
          this.showerror(error);
        }
      },

      async remove(value){
        try {
          let confirm = null;
          await swal({title: "¿eliminar registro?",text: '¿Seguro quiere eliminar este registro?',icon: "error",dangerMode: true,
          buttons: {
            cancel: "CANCELAR",
            confirm: "ACEPTAR"
          }
          }).then(function(conf){
            confirm = conf;
          });

          if(!confirm){
            return;
          }
          this.isprocess = true;
          await axios.delete(this.setting.params.api + '/admin/usuarios/delete/' + value, {
            headers:{ Authorization: localStorage.getItem('token') }
          })
          .then( res => {
            let data = res.data;
            this.isprocess = false;
            if(data.status){
              swal({title: "success",text: data.message,icon: "success",
                buttons: {text: "ACEPTAR"}
              });
              this.load();
            }else{
              this.showerror({message: data.message});
            }
          });
        }catch(error){
          this.showerror(error);
        }
      },

      formatdate(date){
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      },

      showerror(error){
        this.isprocess = false;
        this.isloading = false;
        swal({
          title: "ERROR",
          text: error.message, 
          icon: "error",
          buttons: {
            text: "ACEPTAR",
          }
        });
      },     

    }
  }
</script>