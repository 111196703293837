export default class Donation{

  constructor(){
    this.donante = null;
    this.reference = null;
    this.integrity = null;
    this.documento = null;
    this.formulario = null;
    this.referencia = null;
    this.entidad = null;
    this.tipo = null;
    this.valor = null;
    this.centavos = null;
    this.fecha = null;
  }

}