<div class="my-5">
  <div class="d-flex flex-column justify-center">
    <div class="text-center mb-3">
      <div class="transaction-text-primary">¡Sé parte del cambio!</div>
      <div class="transaction-text-secondary">¿Qué tipo de donación deseas hacer?</div>
    </div>
    <div class="tra-con-btn my-3">
      <v-btn class="t-one mr-1" rounded="lg" variant="flat" size="x-large" prepend-icon="mdi-check-circle" text="única"
      :class="{'bg-primary': !transaction.recurrent}" @click="transaction.recurrent = false"/>
      <v-btn class="t-two ml-1" rounded="lg" variant="flat" size="x-large" prepend-icon="" text="mensual"
      :class="{'bg-primary': transaction.recurrent}" @click="transaction.recurrent = true">
        <template v-slot:prepend>
          <v-icon color="error">mdi-heart</v-icon>
        </template>
      </v-btn>
    </div>
    <div class="tra-con-pay my-3 mx-0">
      <v-btn class="btn1 mr-1" rounded="lg" variant="flat" size="x-large" @click="setamount(form.btn1)"
      :class="{'bg-primary': transaction.amount == form.btn1 || transaction.amount == 0}">
        {{ setting.moneyformat(form.btn1) }} COP
      </v-btn>
      <v-btn class="btn2 ml-1" rounded="lg" variant="flat" size="x-large" @click="setamount(form.btn2)"
      :class="{'bg-primary': transaction.amount == form.btn2}">
        {{ setting.moneyformat(form.btn2) }} COP
      </v-btn>
      <v-btn class="btn3 mr-1" rounded="lg" variant="flat" size="x-large" @click="setamount(form.btn3)"
      :class="{'bg-primary': transaction.amount == form.btn3}">
        {{ setting.moneyformat(form.btn3) }} COP
      </v-btn>
      <v-btn class="btn4 ml-1" rounded="lg" variant="flat" size="x-large" @click="setamount(form.btn4)"
      :class="{'bg-primary': transaction.amount == form.btn4}">
        {{ setting.moneyformat(form.btn4) }} COP
      </v-btn>
      <v-btn class="btn5" rounded="lg" variant="flat" size="x-large" text="otro valor" @click="setcustomamount()"
      :class="{'bg-primary': other}"/>
      <div class="input" v-if="other">
        <v-text-field v-model="transaction.amount" class="w-100" label="Ingrese valor a donar" 
        :error-messages="v$.transaction.amount.$errors.map(e => e.$message)" onkeypress="return event.charCode>=48 && event.charCode<=57"/>
        <div class="transaction-text-secondary w-100">
          <b>*</b> El valor mínimo es $10.000 COP
        </div>
      </div>
    </div>
    <div>
      <div class="transaction-text-secondary mb-3">
        <div v-if="transaction.amount == form.btn1 || transaction.amount == 0">{{ form.msg1 }}</div>
        <div v-if="transaction.amount == form.btn2">{{ form.msg2 }}</div>
        <div v-if="transaction.amount == form.btn3">{{ form.msg3 }}</div>
        <div v-if="transaction.amount == form.btn4">{{ form.msg4 }}</div>
      </div>
      <div class="transaction-text-secondary" v-if="transaction.recurrent">
        <b>IMPORTANTE:</b> Recuerda que estas haciendo una donación mensual por {{ setting.moneyformat(transaction.amount) }} COP, está puede modificada o cancelada en cualquier momento más adelante.
      </div>
    </div>
    <div class="mb-auto"></div>
    <div class="mt-10">
      <v-btn color="warning" block rounded="lg" variant="flat" size="large" text="Continuar" 
      style="color: white !important;bottom: 5px;" @click="following()"/>
    </div>
  </div>
</div>