<div class="border h-100">
  <v-toolbar density="compact">
    <v-toolbar-title>Últimas donaciones</v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="w-25 mr-3">
      <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" density="compact" :elevation="0" label="Search" variant="outlined" hide-details single-line/>
    </div>
  </v-toolbar>
  <v-data-table :headers="headers" :items="data" :loading="isloading" :search="search" density="compact">
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.referencia }}</td>
        <td>{{ item.documento }}</td>
        <td>{{ item.nombres }}</td>
        <td>{{ item.apellidos }}</td>
        <td>{{ setting.moneyformat(item.valor) }}</td>
        <td>
          <v-chip class="text-caption" :color="setting.setcolours(item.estado)">
            {{ item.estado }}
          </v-chip>
        </td>
        <td>{{ setting.formatdate(item.created_at) }}</td>
      </tr>
    </template>
  </v-data-table>
</div>