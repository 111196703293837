<div class="content h-100">
  <NavBar />
  <v-main>
    <v-container fluid>
      <v-card class="pa-5">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex">
              <v-icon size="x-large">mdi-account</v-icon>
              <div class="ml-3 text-h4">
                Módulo Usuarios
              </div>
            </div>
            <div>
              Gestión de usuarios administradores, mercadeo y colectores.
            </div>
          </div>
        </div>
        <div class="mt-5">
          <v-toolbar>
            <v-toolbar-title>Registro de usuarios</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field prepend-inner-icon="mdi-magnify" density="compact" :elevation="0" label="Search" variant="outlined" hide-details single-line/>
            <v-btn icon @click="dialogadd = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <download-excel :data="users" type="xlsx" worksheet="users" name="usuarios.xls" :fields="users_export">
              <v-btn icon>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </download-excel>
          </v-toolbar>
          <v-data-table :headers="headers" :items="users" density="compact" :loading="isloading" :search="search">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-btn icon :elevation="0" density="compact" size="small" @click="remove(item.idusuario)">
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.correo }}</td>
                <td>{{ item.tipo }}</td>
                <td>
                  <v-chip class="text-caption" :color="setting.setcolours(item.estado)">
                    <div v-if="item.estado">habilitado</div>
                    <div v-else>deshabilitado</div>
                  </v-chip>
                </td>
                <td>{{ formatdate(item.created_at) }}</td>
                <td>{{ formatdate(item.updated_at) }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <!-- #region -->
      <v-dialog v-model="dialogadd" max-width="500" persistent>
        <v-card title="Nuevo usuario">
          <v-card-text>
            <div class="text-caption">Por favor complete los sigientes datos.</div>
            <div class="mt-3">
              <v-text-field v-model="user.nombre" label="Nombres" :error-messages="v$.user.nombre.$errors.map(e => e.$message)" />
              <v-text-field v-model="user.correo" label="Correo eletrónico" :error-messages="v$.user.correo.$errors.map(e => e.$message)" />
              <v-text-field v-model="user.token" label="Contraseña" :error-messages="v$.user.token.$errors.map(e => e.$message)" />
              <v-radio-group v-model="user.tipo" inline :error-messages="v$.user.tipo.$errors.map(e => e.$message)">
                <v-radio label="ADMINISTRADOR" value="ADM"></v-radio>
                <v-radio label="COLECTOR" value="COL"></v-radio>
                <v-radio label="MERCADEO" value="MER"></v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text="Cancelar" @click="dialogadd = false"></v-btn>
            <v-btn color="primary" variant="tonal" text="Agregar" :loading="isloading" @click="add"></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- #endregion -->
    
      <!-- #progress -->
      <v-dialog v-model="isprocess" max-width="320" persistent>
        <v-list class="py-2" color="primary" elevation="12" rounded="lg">
          <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
            <template v-slot:prepend>
              <div class="pe-4">
                <v-icon color="primary" size="x-large"></v-icon>
              </div>
            </template>
            <template v-slot:append>
              <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
            </template>
          </v-list-item>
        </v-list>    
      </v-dialog>
      <!-- #endregion -->
    </v-container>
  </v-main>
</div>
