<div class="content h-100">
  <NavBar />
  <v-container class="mt-10" fluid>
    <v-card class="mx-auto my-10" elevation="3" max-width="1200">
      <v-card-text>
        <div class="text-center text-h4 mt-5">FORMULARIO DIGITAL DE VINCULACIÓN AVN</div>
        <div class="text-center text-h6">(Aporte Voluntario por Nómina)</div>
        <div>
          <div class="mb-5">
            <div class="text-h6">1. Datos personales</div>
            <div>Por favor completa los siguientes datos personales.</div>
          </div>            
          <v-row>
            <v-col>
              <v-text-field v-model="donant.nombres" label="Nombres" disabled/>
            </v-col>
            <v-col>
              <v-text-field v-model="donant.apellidos" label="Apellidos" disabled/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-model="donant.edad" label="Rango de edad" 
              :error-messages="v$.donant.edad.$errors.map(e => e.$message)" 
              :items="ages" item-title="label" item-value="value"/>
            </v-col>
            <v-col>
              <v-select v-model="donant.formacion" label="Nivel de formación académica" 
              :error-messages="v$.donant.formacion.$errors.map(e => e.$message)" 
              :items="formations" item-title="label" item-value="value"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="donant.correo_laboral" label="Correl electrónico laboral"
              :error-messages="v$.donant.correo_laboral.$errors.map(e => e.$message)" />
            </v-col>
            <v-col>
              <v-text-field v-model="donant.ciudad" label="Ciudad de residencia"
              :error-messages="v$.donant.ciudad.$errors.map(e => e.$message)" />
            </v-col>
            <v-col>
              <v-text-field v-model="donant.telefono" label="Número de contacto"
              :error-messages="v$.donant.telefono.$errors.map(e => e.$message)" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select v-model="donant.comunicacion" label="Canales de comunicación" :items="['SMS', 'Whatsapp']" multiple 
              :error-messages="v$.donant.comunicacion.$errors.map(e => e.$message)"></v-select>
            </v-col>
            <v-col>
              <v-select v-model="donant.hijos" label="¿Tienes hijos?" :items="['Si', 'No']"
              :error-messages="v$.donant.hijos.$errors.map(e => e.$message)"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select v-model="donant.poder" label="¿Crees en el poder que tiene la educación para superar la pobreza?" :items="['Si', 'No']" 
              :error-messages="v$.donant.poder.$errors.map(e => e.$message)"></v-select>
            </v-col>
          </v-row>
          <div class="mb-5">
            <div class="text-h6">2. Vinculación</div>
            <div>Por favor completa los datos de vinculación.</div>
          </div>
          <v-row>
            <v-col>
              <v-text-field v-model="donant.empresa" label="Empresa" :error-messages="v$.donant.empresa.$errors.map(e => e.$message)"/>
            </v-col>
            <v-col>
              <v-text-field v-model="donant.cargo" label="Cargo" :error-messages="v$.donant.cargo.$errors.map(e => e.$message)"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="donant.aporte" label="Valor a donar mensualmente" 
              onkeypress="return event.charCode>=48 && event.charCode<=57"
              :error-messages="v$.donant.aporte.$errors.map(e => e.$message)"/>
            </v-col>
            <v-col>
              <v-select v-model="donant.incremento_tipo" label="Tipo incremento anual" 
              :error-messages="v$.donant.incremento_tipo.$errors.map(e => e.$message)"
              :items="increment" item-title="label" item-value="value"/>
            </v-col>
            <v-col>
              <v-select label="Incremento valor" :items="incrementstatic" item-title="label" item-value="value" v-if="donant.incremento_tipo == 'fijo'"/>
              <v-text-field label="Incremento valor" v-model="donant.incremento_valor" model-value="IPC anual" readonly v-if="donant.incremento_tipo == 'ipc'" />
              <v-text-field label="Incremento valor" v-model="donant.incremento_valor" v-if="donant.incremento_tipo == 'personalizado'" />
              <v-text-field label="Incremento valor" v-model="donant.incremento_valor" model-value="0" readonly v-if="donant.incremento_tipo == 'ninguno'" />
            </v-col>
          </v-row>
          <div class="text-justify text-caption mb-10">
            Yo, {{donant.nombres}} {{donant.apellidos}}, identificado(a) con Cédula de Ciudadanía No. {{donant.documento}}, autorizo a la sociedad United Way Colombia para que realice un descuento mensual de mi salario por la suma de {{setting.moneyformat(donant.aporte)}} COP, con el fin de aportar a la misión de la Fundación United Way Colombia, hasta el momento en que expresamente revoque esta autorización.
            Dando cumplimiento a lo dispuesto en la Ley 1581 de 2012, el Decreto 1377 de 2013 y la respectiva normatividad vigente sobre el tratamiento de datos personales, autorizo el tratamiento de mis datos personales a la Fundación United Way Colombia, entidad sin ánimo de lucro identificada con el NIT. 830.045.603-6, ubicada en la calle 72 Bis N° 6-44 piso 10 en la ciudad de Bogotá, actúe como responsable del tratamiento de datos personales de los cuales soy titular, para todos los temas asociados a la donación que se otorga mediante este documento y conforme a la política de tratamiento de datos personales de la entidad. 
          </div>
          <div class="d-flex flex-column justify-center w-50 ma-auto">
            <div class="text-center text-h6">Firma del donante</div>
            <div class="text-center text-caption">Por favor firme en el siguiente recuadro.</div>
            <div class="text-center">
              <div>
                <canvas ref="canvas" id="signdraw" width="300" height="150"></canvas>
              </div>
            </div>              
          </div>
          <div class="text-center mb-5 mt-3">
            <v-btn elevation="0" @click="$router.push({name:'managerdonants'})">Cancelar</v-btn>
            <v-btn elevation="0" @click="cleansign">Limpiar firma</v-btn>
            <v-btn color="primary" @click="generate" elevation="0">Acepto y generar autorización</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
</div>