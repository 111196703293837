<div class="h-100">
  <div class="donant-main">
    <div class="donant-picture"></div>
    <div class="donant-form d-flex flex-column justify-center h-100">
      <div class="h-25">
        <router-link to="/manager/access">
          <v-img class="logo-wide" src="@/assets/images/logo-wide.jpg" />
        </router-link>          
      </div>
      <div class="d-flex flex-column justify-center align-center h-50">
        <div class="px-5 w-100">
          <div class="text-center text-h5">Acceso Donantes</div>
          <div class="text-center">Accede para descargar tus certificados de donación.</div>
          <div class="mt-3">
            <v-text-field v-model="document" hide-details="auto" label="Número de documento"
            :error-messages="v$.document.$errors.map(e => e.$message)" 
            onkeypress="return event.charCode>=48 && event.charCode<=57"/>
          </div>
          <div class="mt-3">
            <v-btn color="primary" block variant="flat" size="large" :loading="isloading" text="validar" @click="validate" />
          </div>
          <div class="mt-3">
            <v-alert v-if="error.state" color="error" icon="$error" variant="tonal" title="Error" :text="error.message"></v-alert>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-end h-25 text-caption">
        &#169; Derechos Reservados UWC 2024
      </div>
    </div>
  </div>
</div>