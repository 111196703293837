<template src="./DonantUpdate.html"></template>
<script>
  import axios from 'axios'; 
  import Setting from '@/models/setting';
  import Donant from '@/models/donant';
  import { useVuelidate } from '@vuelidate/core';
  import { numeric, required } from '@vuelidate/validators';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  export default{
    name: 'DonantUpdate',
    components: {
      Loading
    },
    setup () {
      return { v$: useVuelidate() }
    },
    mounted(){
      if(localStorage.donantdocument){
        this.donant.documento = localStorage.donantdocument;
        this.getdata();
      }else{
        this.$router.push({name:'home'});
      }
    },
    data: () => ({
      setting : new Setting(),
      donant : new Donant(),
      overlay: true,
      policy: false,
      locked: true,
      error: {
        state: false,
        message: null
      }
    }),
    validations () {
      return {
        donant:{ 
          nombres: { required },
          apellidos: { required },
          fecha_nacimiento: { required },
          correo_personal: { required },
          telefono: { required, numeric },
          ciudad: { required }
        }
      }
    },

    methods: {

      async getdata(){
        try {
          await axios.get(this.setting.params.api + "/donante/datos/" + localStorage.donantdocument)
          .then( res => {
            let data = res.data;
            this.overlay = false;
            if(data.donante.account_state === 'active'){
              this.$router.push({name: "donantvalidate"});
            }else if(data.status){
              this.donant.setpersonaldata(data.donante);
              this.locked = this.donant.correo_personal ? false : true;
            }else{
              this.error.message = 'Error al cargar los datos.';
              this.error.state = true;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error.message = error.message;
          this.error.state = true;
        }
      },

      async update(){
        const validate = await this.v$.$validate();
        if(!validate){
          return
        }

        try {
          this.overlay = true;
          await axios.put(this.setting.params.api + '/donante/actualizar', this.donant)
          .then( res => {
            this.overlay = false;
            let result = res.data;
            if(result.status){
              this.$router.push({name: "donantvalidate"});
            }else{
              this.error.message = result.message;
              this.error.state = true;
            }
          });
        } catch (error) {
          this.overlay = false;
          this.error.message = error.message;
          this.error.state = true;
        }
      }
    }
  }
</script>