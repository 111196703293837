<div>
  <div class="d-flex align-center justify-space-between">
    <div class="text-h5">Donaciones</div>
    <div class="w-25">
      <v-text-field v-model="search" label="Buscar" append-inner-icon="mdi-magnify" density="compact" variant="outlined"
        hide-details single-line clearable />
    </div>
  </div>
  <div class="mt-3 mb-3">
    <v-data-table :headers="headers" :items="donations" density="compact" :loading="isloading" :search="search">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-btn icon :elevation="0" density="compact" size="small" @click="remove(item.iddonacion)" v-if="user.status">
              <v-icon color="red">mdi-trash-can-outline</v-icon>
            </v-btn>
          </td>
          <td>{{ item.referencia }}</td>
          <td>{{ item.idformulario }}</td>
          <td>{{ item.entidad }}</td>
          <td>{{ item.tipo }}</td>
          <td>{{ setting.moneyformat(item.valor) }}</td>
          <td>{{ item.fecha }}</td>
          <td>
            <v-chip class="text-caption" :color="setting.setcolours(item.estado)">
              {{ item.estado }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
  <!-- #progress -->
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
</div>